Px.Editor.BaseElementModel = class BaseElementModel extends Px.BaseModel {

  static get MIN_Z_VALUE() {
    return -31;
  }

  static get MAX_Z_VALUE() {
    return Px.config.advanced_edit_mode ? 34 : 32;
  }

  constructor(props) {
    let tags = props.tags || [];
    if (typeof props.tags === 'string') {
      tags =  props.tags.split(',').map(t => t.trim());
    }
    props = Object.assign({}, props, {tags: tags});

    super(props);

    if (!this.constructor.ELEMENT_TYPE) {
      throw new Error('Models derived from BaseElementModel require a static `ELEMENT_TYPE` property');
    }
    // Generate unique, read-only ID.
    Object.defineProperty(this, 'unique_id', {
      value: Px.Util.guid(),
      writable: false
    });
    // Reaction which observes element's properties and syncs any changes
    // to element's two page spread clone, if present.
    this.registerReaction(() => {
      if (this.two_page_spread_clone) {
        return this.two_page_spread_clone_properties;
      }
    }, props => {
      if (props) {
        this.two_page_spread_clone.update(props);
      }
    }, {
      name: 'Px.Editor.BaseElementModel::UpdateTwoPageSpreadClonePropertiesReaction'
    });
  }

  // --------------------
  // Static/class methods
  // --------------------

  static getClass(type) {
    const classes = {
      'image': Px.Editor.ImageElementModel,
      'text':  Px.Editor.TextElementModel,
      'barcode': Px.Editor.BarcodeElementModel,
      'score': Px.Editor.ScoreElementModel,
      'grid': Px.Editor.GridElementModel,
      'group': Px.Editor.GroupElementModel,
      'calendar': Px.Editor.CalendarElementModel,
      'pdf': Px.Editor.PdfElementModel,
      'ipage': Px.Editor.InlinePageElementModel
    };
    const cls = classes[type];
    if (!cls) {
      throw new Error(`Unknown element type: ${type}`);
    }
    return cls;
  }

  static factory(type, props) {
    const cls = Px.Editor.BaseElementModel.getClass(type);
    return cls.make(props);
  }

  static fromXMLNode(node, params) {
    let type = node.tagName.toLowerCase();
    if (type === 'g') {
      const tags = (node.getAttribute('tags') || '').split(',').map(t => t.trim());
      if (tags.includes('calendar-element')) {
        type = 'calendar';
      } else {
        type = 'group';
      }
    }
    const cls = Px.Editor.BaseElementModel.getClass(type);
    return cls.fromXMLNode(node, params);
  }

  static fromXML(xml, params) {
    const xmlnode = Px.Util.parseXML(xml).firstChild;
    return this.fromXMLNode(xmlnode, params);
  }

  // ----------
  // Properties
  // ----------

  static get properties() {
    return Object.assign(super.properties, {
      _width: {std: 0, serialize: false},
      _height: {std: 0, serialize: false},
      edit: {std: false},
      x: {std: 0, two_page_spread: false},
      y: {std: 0},
      z: {std: 0},
      rotation: {std: 0, serialize: false},
      erotation: {std: true},
      opacity: {std: 1},
      pdf_layer: {std: null},
      layout: {std: false},
      name: {std: null},
      move: {std: true},
      resize: {std: true},
      'delete': {std: true},
      is_selected: {std: false, serialize: false},
      is_grabbed: {std: false, serialize: false},
      is_resizing: {std: false, serialize: false},
      is_hovered: {std: false, serialize: false},
      tags: {std: mobx.observable.array(), serialize: false},
      clone_id: {std: null},
      // Set to true after the element has been destroyed and removed from the page.
      _destroyed: {std: false, two_page_spread: false, serialize: false},
      // Circular dependency to the parent page.
      page: {std: null, two_page_spread: false, serialize: false},
      // Circular dependency to the parent group.
      group: {std: null, two_page_spread: false, serialize: false}
    });
  }

  static get computedProperties() {
    return {
      container: function() {
        return this.group || this.page;
      },
      translation_matrix: function() {
        var tx = this.x;
        var ty = this.y;
        return Px.Util.translationMatrix(tx, ty);
      },
      rotation_matrix: function() {
        var r = this.rotation;
        var w = this.width;
        var h = this.height;
        return Px.Util.rotationMatrix(r, w/2, h/2);
      },
      // Returns the transformation matrix that should be applied
      // to the element in order to correctly draw it inside its container.
      // The transormation may consist of a translation and rotation.
      transform_matrix: function() {
        var T = this.translation_matrix;
        var R = this.rotation_matrix;
        return Px.Util.multiplyMatrices([T, R]);
      },
      absolute_transform_matrix: function() {
        var groups = this.parent_groups.slice().reverse();
        var Ms = [this.transform_matrix];
        this.parent_groups.forEach(function(group) {
          Ms.unshift(group.transform_matrix);
        });
        return Px.Util.multiplyMatrices(Ms);
      },
      // Returns the total amount of rotation applied to the element
      // as seen from the page's perspective. For an ungrouped element,
      // this is the same as `element.rotation`.
      absolute_rotation: function() {
        var rotation = this.rotation;
        this.parent_groups.forEach(function(group) {
          rotation += group.rotation;
        });
        return rotation;
      },
      absolute_x: function() {
        // TODO: Implement grouping.
        return this.x;
      },
      absolute_y: function() {
        // TODO: Implement grouping.
        return this.y;
      },
      parent_groups: function() {
        var groups = [];
        var parent = this.group;
        while (parent) {
          groups.push(parent);
          parent = parent.group;
        }
        return groups;
      },
      center_point: function() {
        var x = this.x + (this.width / 2);
        var y = this.y + (this.height / 2);
        return {x: x, y: y};
      },
      // Returns true if at least part of the element lies inside page's viewport.
      // Otherwise return false.
      is_in_viewport: function() {
        const page = this.page;
        var page_width = page.width;
        var page_height = page.height;

        var vertices = this.absoluteVerticePoints({with_border: true});
        var exs = Px.Util.extremeCoords(vertices);

        // Two page spread clones should only be visible if the master element crosses the page boundary.
        // When using gutters, this is different from the standard viewport detection, because when gutters
        // are enabled, part of the clone element would already be visible on the opposite page when the master
        // elements starts entering the gutter area, but before crossing the border of its page.
        // We don't want to duplicate gutter elements that are not meant to cross the page boundary since that
        // produces ugly artifacts. See presentation attached to https://www.pivotaltracker.com/story/show/164647734
        // for more info.
        if (this.two_page_spread_clone && !this.is_master_element) {
          const master = this.two_page_spread_master_clone;
          const master_exs = master.absolute_extreme_coords;
          if ((page.in_leftmost_position && master_exs.min_x >= 0) ||
              (page.in_rightmost_position && master_exs.max_x <= page_width)) {
            return false;
          }
        }

        // If the element is fully visible, all of its extremes must lie inside the page
        var is_fully_visible =
          exs.min_x >= 0 &&
          exs.max_x <= page_width &&
          exs.min_y >= 0 &&
          exs.max_y <= page_height;

        if (is_fully_visible) {
          return true;
        }

        // The element may be covering the whole page, with all of its extremes lying outside
        // the page area.
        var is_covering_page =
          exs.min_x <= 0 &&
          exs.max_x >= page_width &&
          exs.min_y <= 0 &&
          exs.max_y >= page_height;

        if (is_covering_page) {
          return true;
        }

        // If it's neither fully visible nor covering the whole page,
        // it may still be partially visible.
        // In that case it must intersect at least one of page's edges.
        var v1x = vertices[0][0];
        var v1y = vertices[0][1];
        var v2x = vertices[1][0];
        var v2y = vertices[1][1];
        var v3x = vertices[2][0];
        var v3y = vertices[2][1];
        var v4x = vertices[3][0];
        var v4y = vertices[3][1];

        var element_edges = [
          [v1x, v1y, v2x, v2y],
          [v2x, v2y, v3x, v3y],
          [v3x, v3y, v4x, v4y],
          [v4x, v4y, v1x, v1y]
        ];
        var page_edges = [
          [0, 0, page_width, 0],
          [page_width, 0, page_width, page_height],
          [page_width, page_height, 0, page_height],
          [0, page_height, 0, 0]
        ];

        for (var i = 0; i < 4; i++) {
          for (var j = 0; j < 4; j++) {
            if (Px.Util.lineIntersection(element_edges[i], page_edges[j])) {
              return true;
            }
          }
        }

        return false;
      },
      is_master_element: function() {
        return !this.two_page_spread_master_clone || (this.two_page_spread_master_clone === this);
      },
      // This returns true if the image element is suitable for being edited by an automated
      // process such as autofill.
      is_editable_master_element: function() {
        return this.edit && this.is_in_viewport && this.is_master_element;
      },
      is_unedited: function() {
        return this.is_editable_master_element && this.placeholder;
      },
      opposite_page: function() {
        var opposite_page = null;
        var set = this.page && this.page.set;
        var page_id = this.page && this.page.id;
        if (page_id && set && set.pages.length === 2) {
          opposite_page = _.find(set.pages, function(page) {
            return page.id !== page_id;
          });
        }
        return opposite_page;
      },
      two_page_spread_clone: function() {
        var clone_id = this.clone_id;
        if (Px.config.two_page_spread && clone_id && this.opposite_page) {
          return _.find(this.opposite_page.elements, function(element) {
            return element.clone_id === clone_id;
          });
        }
        return null;
      },
      // When two page spread is enabled, each element comes in two clones (copies).
      // The master clone is the copy belonging to the page that contains the largest area
      // of the element.
      two_page_spread_master_clone: function() {
        if (this.two_page_spread_clone) {
          var center_x = this.center_point.x;
          if ((this.page.in_leftmost_position && center_x <= this.page.width) ||
              (this.page.in_rightmost_position && center_x >= 0)) {
            return this;
          } else {
            return this.two_page_spread_clone;
          }
        }
        return null;
      },
      two_page_spread_clone_x_offset: function() {
        var offset;
        // If the object is part of a group, the x coordinate should not be adjusted.
        if (this.group) {
          offset = 0;
        } else {
          offset = this.page.width - (this.page.gutter + this.opposite_page.gutter);
          if (this.page.in_leftmost_position) {
            offset *= -1;
          }
        }
        return offset;
      },
      two_page_spread_clone_properties: function() {
        var properties = {};
        _.each(this.constructor.properties, (spec, name) => {
          if (spec.two_page_spread !== false) {
            properties[name] = this[name];
          }
        });
        properties.x = this.x + this.two_page_spread_clone_x_offset;
        return properties;
      },
      xml: function() {
        return `<${this.type} ${this.xmlizeAttributes()}/>`;
      },
      extreme_coords: function() {
        const vertices = this.verticePoints({with_border: true});
        return Px.Util.extremeCoords(vertices);
      },
      absolute_extreme_coords: function() {
        const vertices = this.absoluteVerticePoints({with_border: true});
        return Px.Util.extremeCoords(vertices);
      },
      // Tries to guess the correct caption for the element's containing page.
      // This is not always straight-forward since captions are define on sets, not individual pages.
      // If the page is a double page, returns the caption for the part which contains most of the element.
      page_caption: function() {
        const set = this.page.set;
        if (set.double_page) {
          if (this.center_point.x > (this.page.width / 2) && set.right_caption) {
            return set.right_caption;
          }
        }
        var caption = this.page.position === 0 ? set.left_caption : set.right_caption;
        if (!caption) {
          caption = set.center_caption;
        }
        return caption;
      }
    };
  }

  get actions() {
    return Object.assign(super.actions, {
      destroy: function() {
        if (!this.destroyed) {
          const clone = this.two_page_spread_clone;
          if (this.group) {
            this.group.removeElement(this);
          }
          this.page.removeElement(this);
          this._destroyed = true;
          if (clone) {
            clone.destroy();
          }
        }
      },
      createTwoPageSpreadClone: function() {
        if (this.opposite_page) {
          if (!this.clone_id) {
            this.clone_id = Px.Util.guid();
          }
          var clone_element = this.clone({page: this.opposite_page});
          clone_element.update(this.two_page_spread_clone_properties);
          var position = this._getClonePosition(clone_element);
          this.opposite_page._elements.splice(position, 0, clone_element);
        }
      }
    });
  }

  // Not a very efficient way to clone an Element (goes via XML).
  clone(params) {
    params = params || {};
    return Px.Editor.BaseElementModel.fromXML(this.xml, params);
  }

  // Returns an array of objects that represent element alignments against other elements, the page center,
  // edges of the page, or beginning of the bleed area.
  // Each object contains these attributes:
  // - match: one of 'top', 'right', 'bottom', 'left', 'vcenter', 'hcenter'
  // - element: reference to the aligned element (is `null` for 'vcenter' and 'hcenter' match)
  // - x: only when `match` is one of 'left', 'right', 'vcenter'
  // - y: only when `match` is one of 'top', 'bottom', 'hcenter'
  elementAlignments(precision, grid, exclude_elements) {
    if (!Px.config.element_alignment) {
      return [];
    }
    if (!exclude_elements) {
      exclude_elements = [this];
    }
    var page = this.page;
    var alignments = [];
    // TODO: Support alignment highlights for elements rotated for 90, 180, 270 degrees.
    // Don't show alignments for elements that are not movable, since they are
    // more distractful than helpful (except in the admin, where we show them for
    // non-movable elements as well).
    if ((this.move || this.resize || Px.config.advanced_edit_mode)  && !this.rotation) {
      var x = this.absolute_x;
      var y = this.absolute_y;
      var half_border = (this.border || 0) / 2;
      // Get top left and bottom right corners of the element.
      var reference_x1 = x - half_border;
      var reference_y1 = y - half_border;
      var reference_x2 = x + this.width + half_border;
      var reference_y2 = y + this.height + half_border;
      // Walk the page elements to collect aligned segments.
      page.elements.forEach(element => {
        if (!(exclude_elements.includes(element) || element.type === 'score' || element.rotation)) {
          var h = element.height;
          var w = element.width;
          var x = element.x;
          var y = element.y;
          var b = (element.border || 0) / 2;
          var x1 = x - b;
          var y1 = y - b;
          var x2 = x + w + b;
          var y2 = y + h + b;
          // Horizontal alignments.
          if (Math.abs(y1 - reference_y1) < precision) {
            alignments.push({y: y1, match: 'top', element: element});
          } else if (Math.abs(y1 - reference_y2) < precision) {
            alignments.push({y: y1, match: 'bottom', element: element});
          }
          if (Math.abs(y2 - reference_y1) < precision) {
            alignments.push({y: y2, match: 'top', element: element});
          } else if (Math.abs(y2 - reference_y2) < precision) {
            alignments.push({y: y2, match: 'bottom', element: element});
          }
          // Vertical alignments.
          if (Math.abs(x1 - reference_x1) < precision) {
            alignments.push({x: x1, match: 'left', element: element});
          } else if (Math.abs(x1 - reference_x2) < precision) {
            alignments.push({x: x1, match: 'right', element: element});
          }
          if (Math.abs(x2 - reference_x1) < precision) {
            alignments.push({x: x2, match: 'left', element: element});
          } else if (Math.abs(x2 - reference_x2) < precision) {
            alignments.push({x: x2, match: 'right', element: element});
          }
        }
      });
      // Check for center page alignment.
      var page_center = {
        x: page.width / 2,
        y: page.height / 2
      };
      var element_center = {
        x: this.absolute_x + (this.width / 2),
        y: this.absolute_y + (this.height / 2)
      };
      if (Math.abs(element_center.x - page_center.x) < precision) {
        alignments.push({x: page_center.x, match: 'vcenter', element: null});
      }
      if (Math.abs(element_center.y - page_center.y) < precision) {
        alignments.push({y: page_center.y, match: 'hcenter', element: null});
      }
      // If this page has a binding, it must be the cover page.
      // Check for centering on either half of the cover, taking the hinge into account, if present.
      var binding = _.find(page.score_elements, function(score) {
        return score.name === 'binding';
      });
      var hinge = binding && _.find(page.score_elements, function(score) {
        return score.name === 'hinge';
      });
      var hinge_area_width = hinge ? hinge.width : (binding ? binding.width : 0);
      if (binding) {
        var cover_width = page.width/2 - (page.bleed + hinge_area_width/2);
        var left_center_x = page.bleed + cover_width / 2;
        var right_center_x = (page.width + hinge_area_width + cover_width) / 2;

        if (Math.abs(element_center.x - left_center_x) < precision) {
          alignments.push({x: left_center_x, match: 'vcenter', element: null});
        } else if (Math.abs(element_center.x - right_center_x) < precision) {
          alignments.push({x: right_center_x, match: 'vcenter', element: null});
        }
      }
      // Check for custom snap point alignments.
      if (page.snap_points.length) {
        page.snap_points.forEach(snap => {
          if (Math.abs(reference_x1 - snap) < precision) {
            alignments.push({x: snap, match: 'left'});
          }
          if (Math.abs(reference_x2 - (page.width - snap)) < precision) {
            alignments.push({x: page.width - snap, match: 'right'});
          }
          if (Math.abs(reference_y1 - snap) < precision) {
            alignments.push({y: snap, match: 'top'});
          }
          if (Math.abs(reference_y2 - (page.height - snap)) < precision) {
            alignments.push({y: page.height - snap, match: 'bottom'});
          }
        });
      } else {
        // Check for page edge alignments.
        if (Math.abs(reference_x1) < precision) {
          alignments.push({x: 0, match: 'left'});
        }
        if (Math.abs(reference_x2 - page.width) < precision) {
          alignments.push({x: page.width, match: 'right'});
        }
        if (Math.abs(reference_y1) < precision) {
          alignments.push({y: 0, match: 'top'});
        }
        if (Math.abs(reference_y2 - page.height) < precision) {
          alignments.push({y: page.height, match: 'bottom'});
        }
        // Check for bleed + margin alignment.
        if (page.bleed || page.margin) {
          const unsafe_zone_width = page.bleed + page.margin;
          if (Math.abs(reference_x1 - unsafe_zone_width) < precision) {
            alignments.push({x: unsafe_zone_width, match: 'left'});
          }
          if (Math.abs(reference_x2 - (page.width - unsafe_zone_width)) < precision) {
            alignments.push({x: page.width - unsafe_zone_width, match: 'right'});
          }
          if (Math.abs(reference_y1 - unsafe_zone_width) < precision) {
            alignments.push({y: unsafe_zone_width, match: 'top'});
          }
          if (Math.abs(reference_y2 - (page.height - unsafe_zone_width)) < precision) {
            alignments.push({y: page.height - unsafe_zone_width, match: 'bottom'});
          }
        }
      }
      if (binding) {
        if (Math.abs(reference_x1 - (page.width - hinge_area_width)/2) < precision) {
          alignments.push({x: (page.width - hinge_area_width) / 2, match: 'left'});
        }
        if (Math.abs(reference_x1 - (page.width + hinge_area_width)/2) < precision) {
          alignments.push({x: (page.width + hinge_area_width) / 2, match: 'left'});
        }
        if (Math.abs(reference_x2 - (page.width - hinge_area_width)/2) < precision) {
          alignments.push({x: (page.width - hinge_area_width) / 2, match: 'right'});
        }
        if (Math.abs(reference_x2 - (page.width + hinge_area_width)/2) < precision) {
          alignments.push({x: (page.width + hinge_area_width) / 2, match: 'right'});
        }
      }
      if (grid) {
        page.gridLines(grid.vblocks, grid.hblocks).forEach(line => {
          if (line.hasOwnProperty('x')) {
            if (Math.abs(reference_x1 - line.x) < precision) {
              alignments.push({x: line.x, match: 'left'});
            }
            if (Math.abs(reference_x2 - line.x) < precision) {
              alignments.push({x: line.x, match: 'right'});
            }
          } else {
            if (Math.abs(reference_y1 - line.y) < precision) {
              alignments.push({y: line.y, match: 'top'});
            }
            if (Math.abs(reference_y2 - line.y) < precision) {
              alignments.push({y: line.y, match: 'bottom'});
            }
          }
        });
      }
    }
    return alignments;
  }

  _translationSnapPoint(x, y, grid, exclude_elements, include_clone) {
    var half_border = (this.border || 0) / 2;
    var snap_x = null;
    var snap_y = null;
    var width = this.width;
    var height = this.height;
    var left_x = x - half_border;
    var right_x = x + width + half_border;
    var top_y = y - half_border;
    var bottom_y = y + height + half_border;
    var center_x = x + width/2;
    var center_y = x + height/2;
    var precision = BaseElementModel.ELEMENT_ALIGNMENT_SNAP_TOLERANCE_PIXELS;
    this.elementAlignments(precision, grid, exclude_elements).forEach(function(alignment) {
      switch (alignment.match) {
      case 'left':
        if (snap_x === null || Math.abs(snap_x - left_x) > Math.abs(alignment.x - left_x)) {
          snap_x = alignment.x + half_border;
        }
        break;
      case 'right':
        if (snap_x === null || Math.abs(snap_x - left_x) > Math.abs(alignment.x - right_x)) {
          snap_x = alignment.x - (width + half_border);
        }
        break;
      case 'top':
        if (snap_y === null || Math.abs(snap_y - top_y) > Math.abs(alignment.y - top_y)) {
          snap_y = alignment.y + half_border;
        }
        break;
      case 'bottom':
        if (snap_y === null || Math.abs(snap_y - top_y) > Math.abs(alignment.y - bottom_y)) {
          snap_y = alignment.y - (height + half_border);
        }
        break;
      case 'vcenter':
        if (snap_x === null || Math.abs(snap_x - left_x) > Math.abs(alignment.x - center_x)) {
          snap_x = alignment.x - (width / 2);
        }
        break;
      case 'hcenter':
        if (snap_y === null || Math.abs(snap_y - top_y) > Math.abs(alignment.y - center_y)) {
          snap_y = alignment.y - (height / 2);
        }
        break;
      }
    });
    if (snap_x === null && this.two_page_spread_clone && include_clone) {
      const clone_snap_point = this.two_page_spread_clone._translationSnapPoint(
        x + this.two_page_spread_clone_x_offset,
        y,
        grid,
        exclude_elements.map(element => element.two_page_spread_clone).filter(element => element !== null),
        false
      );
      if (clone_snap_point.x !== null) {
        snap_x = clone_snap_point.x - this.two_page_spread_clone_x_offset;
      }
    }
    return {x: snap_x, y: snap_y};
  }

  translationSnapPoint(x, y, grid, exclude_elements) {
    if (!exclude_elements) {
      exclude_elements = [this];
    }
    return this._translationSnapPoint(x, y, grid, exclude_elements, true);
  }

  _resizingSnapPoint(x, y, width, height, active_handle, grid, include_clone) {
    var half_border = (this.border || 0) / 2;
    var snap_x = null;
    var snap_y = null;
    var snap_width = null;
    var snap_height = null;
    var left_x = x - half_border;
    var right_x = x + width + half_border;
    var top_y = y - half_border;
    var bottom_y = y + height + half_border;
    var aspect_ratio = height !== 0 ? width / height : 0;
    this.elementAlignments(BaseElementModel.ELEMENT_ALIGNMENT_SNAP_TOLERANCE_PIXELS, grid).forEach(function(alignment) {
      switch (active_handle) {
      case 'top-left':
        if (alignment.match === 'top') {
          if (snap_y === null || Math.abs(snap_y - top_y) > Math.abs(alignment.y - top_y)) {
            snap_y = alignment.y + half_border;
            snap_height = height + (y - snap_y);
            snap_width = snap_height * aspect_ratio;
            snap_x = x + (width - snap_width);
          }
        } else if (alignment.match === 'left') {
          if (snap_x === null || Math.abs(snap_x - left_x) > Math.abs(alignment.x - left_x)) {
            snap_x = alignment.x + half_border;
            snap_width = width + (x - snap_x);
            snap_height = aspect_ratio > 0 ? snap_width / aspect_ratio : height;
            snap_y = y + (height - snap_height);
          }
        }
        break;
      case 'top':
        if (alignment.match === 'top') {
          if (snap_y === null || Math.abs(snap_y - top_y) > Math.abs(alignment.y - top_y)) {
            snap_y = alignment.y + half_border;
            snap_height = height + (y - snap_y);
          }
        }
        break;
      case 'top-right':
        if (alignment.match === 'top') {
          if (snap_y === null || Math.abs(snap_y - top_y) > Math.abs(alignment.y - top_y)) {
            snap_y = alignment.y + half_border;
            snap_height = height + (y - snap_y);
            snap_width = snap_height * aspect_ratio;
          }
        } else if (alignment.match === 'right') {
          if (snap_width === null || Math.abs(snap_width - width) > Math.abs(alignment.x - right_x)) {
            snap_width = width + (alignment.x - right_x);
            snap_height = aspect_ratio > 0 ? snap_width / aspect_ratio : height;
            snap_y = top_y + (height - snap_height);
          }
        }
        break;
      case 'right':
        if (alignment.match === 'right') {
          if (snap_width === null || Math.abs((left_x + snap_width) - right_x) > Math.abs(alignment.x - right_x)) {
            snap_width = width + (alignment.x - right_x);
          }
        }
        break;
      case 'bottom-right':
        if (alignment.match === 'bottom') {
          if (snap_height === null || Math.abs(snap_height - height) > Math.abs(alignment.y - bottom_y)) {
            snap_height = height + (alignment.y - bottom_y);
            snap_width = snap_height * aspect_ratio;
          }
        } else if (alignment.match === 'right') {
          if (snap_width === null || Math.abs(snap_width - width) > Math.abs(alignment.x - right_x)) {
            snap_width = width + (alignment.x - right_x);
            snap_height = aspect_ratio > 0 ? snap_width / aspect_ratio : height;
          }
        }
        break;
      case 'bottom':
        if (alignment.match === 'bottom') {
          if (snap_height === null || Math.abs((top_y + snap_height) - bottom_y) > Math.abs(alignment.y - bottom_y)) {
            snap_height = height + (alignment.y - bottom_y);
          }
        }
        break;
      case 'bottom-left':
        if (alignment.match === 'bottom') {
          if (snap_height === null || Math.abs(snap_height - height) > Math.abs(alignment.y - bottom_y)) {
            snap_height = height + (alignment.y - bottom_y);
            snap_width = snap_height * aspect_ratio;
            snap_x = left_x + (width - snap_width);
          }
        } else if (alignment.match === 'left') {
          if (snap_x === null || Math.abs(snap_x - left_x) > Math.abs(alignment.x - left_x)) {
            snap_x = alignment.x + half_border;
            snap_width = width + (x - snap_x);
            snap_height = aspect_ratio > 0 ? snap_width / aspect_ratio : height;
          }
        }
        break;
      case 'left':
        if (alignment.match === 'left') {
          if (snap_x === null || Math.abs(snap_x - left_x) > Math.abs(alignment.x - left_x)) {
            snap_x = alignment.x + half_border;
            snap_width = width + (x - snap_x);
          }
        }
        break;
      }
    });
    if ((snap_x === null && snap_y === null && snap_width === null && snap_height === null) &&
        this.two_page_spread_clone && include_clone) {
      const clone_snap_point = this.two_page_spread_clone._resizingSnapPoint(
        x + this.two_page_spread_clone_x_offset,
        y,
        width,
        height,
        active_handle,
        grid,
        false
      );
      if (clone_snap_point.x !== null) {
        snap_x = clone_snap_point.x - this.two_page_spread_clone_x_offset;
      }
      snap_width = clone_snap_point.width;
      snap_y = clone_snap_point.y;
      snap_height = clone_snap_point.height;
    }
    return {
      x: snap_x,
      y: snap_y,
      width: snap_width,
      height: snap_height
    };
  }

  resizingSnapPoint(x, y, width, height, active_handle, grid) {
    return this._resizingSnapPoint(x, y, width, height, active_handle, grid, true);
  }

  // Returns an array of coordinates of elements vertices.
  // Supported options are `with_border` and `absolute` (both default to false).
  // When `with_border` is true, the vertices are taken to lie on the edge of the borders,
  // otherwise the vertices correspond to the SVG rect's vertices.
  verticePoints(opts) {
    opts = _.extend({with_border: false, absolute: false}, opts);

    var border = opts.with_border ? (this.border || 0) : 0;
    var half_border = border / 2;
    var width = this.width;
    var height = this.height;

    var A = [-half_border, -half_border];
    var B = [width + half_border, -half_border];
    var C = [width + half_border, height + half_border];
    var D = [-half_border, height + half_border];

    var M = opts.absolute ? this.absolute_transform_matrix : this.transform_matrix;
    var points = [A, B, C, D];
    var vertices = _.map(points, function(point) {
      return Px.Util.applyMatrix(M, point);
    });

    return vertices;
  }

  // The same as `verticePoints`, except that it returns the points in the absolute (page) coordinates.
  absoluteVerticePoints(opts) {
    opts = _.extend({absolute: true}, opts);
    return this.verticePoints(opts);
  }

  // ---------------
  // Getters/setters
  // ---------------

  get type() {
    return this.constructor.ELEMENT_TYPE;
  }

  get width() {
    // We force a 2 mm min width on text elements.
    const min_width = this.type === 'text' ? 2 : 0;
    return Math.max(min_width, this._width);
  }

  set width(width) {
    this._width = width;
  }

  get height() {
    // We force a 2 mm min height on text elements.
    const min_height = this.type === 'text' ? 2 : 0;
    return Math.max(min_height, this._height);
  }

  set height(height) {
    this._height = height;
  }

  // -------
  // Private
  // -------

  // In order to maintain a consistent z-ordering of clones on both pages,
  // we cannot simply append a new clone element to page._elements.
  // Elements are ordered by their z value, but elements with the same z value
  // and elements which do not support z ordering, are ordered by their position
  // inside the page._elements array.
  // This function finds the correct index to place the new clone element at inside page._elements.
  // It is faaaar from elegant, but does seem to work.
  _getClonePosition(clone) {
    // Get "siblings" - elements from the same layer.
    // Conveniently, this also works for elements that don't have a 'z' property.
    var z = this.z;
    var siblings = _.filter(this.container._elements, function(ele) {
      return ele.z === z && ele.clone_id;
    });

    // We are only interested in elements after the current element.
    var idx = _.indexOf(siblings, this);
    siblings = siblings.slice(idx + 1);

    // Now compare "our" siblings to the clone's siblings;
    // find first clone's sibling that is a clone of one of "our" siblings.
    var clone_siblings = _.filter(clone.container._elements, function(ele) {
      return ele.z === z && ele.clone_id;
    });

    var clone_sibling_ids = _.map(clone_siblings, function(ele) {
      return ele.clone_id;
    });

    var matching_sibling = _.find(siblings, function(sibling) {
      return _.include(clone_sibling_ids, sibling.clone_id);
    });

    var target_idx = -1;
    // If we got a matching sibling, sync the clone's z-position.
    if (matching_sibling) {
      target_idx = _.findIndex(clone_siblings, function(ele) {
        return ele.clone_id === matching_sibling.clone_id;
      });
    }

    return target_idx === -1 ? clone.container._elements.length : target_idx;
  }

  serializableAttributes() {
    var attrs = {};
    _.each(this.constructor.properties, (spec, key) => {
      if (spec.serialize !== false) {
        attrs[key] = this[key];
      }
    });
    attrs.width = this.width;
    attrs.height = this.height;
    if (this.rotation) {
      attrs.rotate = this.rotation;
    }
    if (this.tags.length > 0) {
      attrs.tags = this.tags.join(',');
    }
    return attrs;
  }

  xmlizeAttributes() {
    var attributes = this.serializableAttributes();
    var keys = Object.keys(attributes).sort();
    var output = [];
    keys.forEach(key => {
      var value = attributes[key];
      var spec = this.constructor.properties[key] || {std: null};
      if (value !== spec.std) {
        var serialized = this.stringifyAttributeValue(value);
        output.push(`${key}="${_.escape(serialized)}"`);
      }
    });
    return output.join(' ');
  }

  stringifyAttributeValue(value) {
    if (typeof value === 'number') {
      // Make sure the value has at most 12 decimals.
      const str = value.toFixed(12);
      // Strip insignificant zeros to save space.
      return str.replace(/\.?0+$/, '');
    } else {
      return value.toString();
    }
  }

};

Px.Editor.BaseElementModel.ELEMENT_ALIGNMENT_SNAP_TOLERANCE_PIXELS = 7;

// Elements participate in two page spread by default.
Px.Editor.BaseElementModel.TWO_PAGE_SPREAD_ENABLED = true;
