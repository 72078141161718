Px.Editor.ProjectGalleryStore = class ProjectGalleryStore extends Px.Editor.BaseUploadGalleryStore {

  constructor(project_id, image_store) {
    super(image_store);
    this.project_id = project_id;
  };

  static get properties() {
    return Object.assign(super.properties, {
      gallery_id: {std: null}
    });
  }

  static get computedProperties() {
    return Object.assign(super.computedProperties, {
      gallery_url: function() {
        if (!this.gallery_id) {
          return null;
        } else {
          return `/v1/galleries/${this.gallery_id}/images.json`;
        }
      }
    });
  }

  get actions() {
    return Object.assign(super.actions, {
      load: function() {
        return fetch(`/v1/books/${this.project_id}/gallery.json`).then(r => r.json()).then(gallery => {
          this.gallery_id = gallery.id;
        }).then(() => {
          this.loadGalleryImages();
        });
      }
    });
  }

};
