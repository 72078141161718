import '../common/js/framework.js'

import './lib/liquid.browser.umd-10.9.2.js'
import './lib/luxon-1.22.0.js'
import './lib/rrule-2.6.4.js'
import './lib/pdf-lib-1.16.0.js'
import './lib/pdfjs-2.5.207.js'

(() => {
  const url_parts = document.currentScript.src.split('/');
  url_parts.pop();
  url_parts.push('pdfjs_worker_bundle.js');
  pdfjsLib.GlobalWorkerOptions.workerSrc = url_parts.join('/');
})();

import './app/init.js'
import './app/integrations.js';
import './app/integrations/shopify.js';
import './app/models/base_element_model.js'

// Mixins
import './app/components/mixins/drag_and_drop_to_page_mixin.js'
import './app/components/mixins/svg_element_mixin.js'
import './app/models/mixins/element_container_mixin.js'

// Components
import './app/components/base_component.js'
import './app/components/base_component.js'
import './app/components/base_element_component.js'
import './app/components/barcode_edit_panel.js'
import './app/components/image_gallery_panels/base_gallery_panel.js'
import './app/components/image_gallery_panels/user_gallery_panel.js'
import './app/components/image_gallery_panels/album_gallery_panel.js'
import './app/components/image_gallery_panels/dropbox_gallery_panel.js'
import './app/components/image_gallery_panels/facebook_gallery_panel.js'
import './app/components/image_gallery_panels/group_gallery_panel.js'
import './app/components/image_gallery_panels/instagram_gallery_panel.js'
import './app/components/image_gallery_panels/project_gallery_panel.js'
import './app/components/image_gallery_panels/theme_resource_gallery_panel.js'
import './app/components/image_gallery_panels/mobile_gallery_panel_mixin.js'
import './app/components/image_gallery_panels/mobile_dropbox_gallery_panel.js'
import './app/components/image_gallery_panels/mobile_facebook_gallery_panel.js'
import './app/components/image_gallery_panels/mobile_instagram_gallery_panel.js'
import './app/components/image_gallery_panels/mobile_user_gallery_panel.js'
import './app/components/advanced_edit_panel.js'
import './app/components/admin_elements_panel.js'
import './app/components/backgrounds_panel.js'
import './app/components/barcode_edit_accordion.js'
import './app/components/barcode_element.js'
import './app/components/calendar_edit_accordion.js'
import './app/components/calendar_edit_panel.js'
import './app/components/clipart_panel.js'
import './app/components/color_picker_modal.js'
import './app/components/coordinate_input.js'
import './app/components/cut_prints.js'
import './app/components/dates_edit_panel.js'
import './app/components/element_bleed_warning.js'
import './app/components/element_crop_icon.js'
import './app/components/element_delete_icon.js'
import './app/components/element_edit_icons_container.js'
import './app/components/element_layer_icons.js'
import './app/components/element_rotate_icon.js'
import './app/components/element_icon.js'
import './app/components/element_resize_handles.js'
import './app/components/exit_project_modal.js'
import './app/components/grid_element.js'
import './app/components/group_element.js'
import './app/components/group_edit_accordion.js'
import './app/components/group_edit_panel.js'
import './app/components/calendar_element.js'
import './app/components/header.js'
import './app/components/image_clear_icon.js'
import './app/components/image_edit_accordion.js'
import './app/components/image_edit_panel.js'
import './app/components/image_element.js'
import './app/components/image_import_panel.js'
import './app/components/image_mask_panel.js'
import './app/components/image_swap_icon.js'
import './app/components/image_turn_icons.js'
import './app/components/image_upload_progress_indicator.js'
import './app/components/images_panel.js'
import './app/components/inline_page_edit_accordion.js'
import './app/components/inline_page_edit_panel.js'
import './app/components/inline_page_element.js'
import './app/components/inline_page_mask_panel.js'
import './app/components/view_settings_panel.js'
import './app/components/layouts_panel.js'
import './app/components/main.js'
import './app/components/mapped_preview_panel.js'
import './app/components/mobile_autofill_panel.js'
import './app/components/mobile_cut_print_quantity_panel.js'
import './app/components/mobile_header.js'
import './app/components/mobile_image_selector_panel.js'
import './app/components/page_display.js'
import './app/components/mobile_page_display.js'
import './app/components/mobile_page_list.js'
import './app/components/mobile_image_edit_tools.js'
import './app/components/mobile_text_edit_tools.js'
import './app/components/mobile_layouts_panel.js'
import './app/components/mobile_sidebar_panel.js'
import './app/components/mobile_toolbar.js'
import './app/components/notification.js'
import './app/components/options_panel.js'
import './app/components/organize_pages.js'
import './app/components/page_mask_panel.js'
import './app/components/page_navigation.js'
import './app/components/page_settings_panel.js'
import './app/components/page.js'
import './app/components/palette_color_picker.js'
import './app/components/pdf_edit_accordion.js'
import './app/components/pdf_edit_panel.js'
import './app/components/pdf_element.js'
import './app/components/pdf_import_modal.js'
import './app/components/quicksave_project_modal.js'
import './app/components/qr_uploader.js'
import './app/components/save_project_modal.js'
import './app/components/upload_method_modal.js'
import './app/components/upload_project_images_modal.js'
import './app/components/unedited_elements_modal.js'
import './app/components/score_element.js'
import './app/components/spinner_modal.js'
import './app/components/tagged_items_panel.js'
import './app/components/text_alignment_buttons.js'
import './app/components/text_edit_accordion.js'
import './app/components/text_edit_modal.js'
import './app/components/text_edit_panel.js'
import './app/components/text_element.js'
import './app/components/texts_panel.js'
import './app/components/vertical_tabbed_menu.js'

// Models
import './app/models/barcode_element_model.js'
import './app/models/base_image_model.js'
import './app/models/base_pdf_model.js'
import './app/models/base_tagged_image_model.js'
import './app/models/color_palette_model.js'
import './app/models/db_image_model.js'
import './app/models/db_pdf_model.js'
import './app/models/dropbox_image_model.js'
import './app/models/element_selection_model.js'
import './app/models/fb_image_model.js'
import './app/models/font_palette_model.js'
import './app/models/grid_element_model.js'
import './app/models/group_element_model.js'
import './app/models/calendar_element_model.js'
import './app/models/image_element_model.js'
import './app/models/inline_page_element_model.js'
import './app/models/instagram_image_model.js'
import './app/models/page_model.js'
import './app/models/layout_model.js'
import './app/models/local_image_model.js'
import './app/models/local_pdf_model.js'
import './app/models/missing_image_model.js'
import './app/models/notification_model.js'
import './app/models/page_definition_model.js'
import './app/models/page_set_model.js'
import './app/models/pdf_element_model.js'
import './app/models/score_element_model.js'
import './app/models/set_definition_model.js'
import './app/models/text_element_model.js'
import './app/models/text_model.js'
import './app/models/theme_template_model.js'
import './app/models/undo_snapshot_model.js'

// Stores
import './app/stores/base_project_store.js'
import './app/stores/base_theme_gallery_store.js'
import './app/stores/base_upload_gallery_store.js'
import './app/stores/background_store.js'
import './app/stores/book_project_store.js'
import './app/stores/clipart_gallery_store.js'
import './app/stores/clipboard_store.js'
import './app/stores/color_palette_store.js'
import './app/stores/cut_print_store.js'
import './app/stores/dropbox_gallery_store.js'
import './app/stores/facebook_gallery_store.js'
import './app/stores/font_palette_store.js'
import './app/stores/gallery_store.js'
import './app/stores/group_gallery_store.js'
import './app/stores/image_store.js'
import './app/stores/instagram_gallery_store.js'
import './app/stores/layout_store.js'
import './app/stores/main_store.js'
import './app/stores/mask_gallery_store.js'
import './app/stores/mobile_store.js'
import './app/stores/notification_store.js'
import './app/stores/option_store.js'
import './app/stores/pdf_store.js'
import './app/stores/project_gallery_store.js'
import './app/stores/text_store.js'
import './app/stores/theme_store.js'
import './app/stores/theme_gallery_store.js'
import './app/stores/theme_layouts_project_store.js'
import './app/stores/theme_templates_project_store.js'
import './app/stores/ui_store.js'
import './app/stores/undo_redo_store.js'
import './app/stores/user_gallery_store.js'

// CSS
import './css/normalize.css'
//
import './css/main.css'
// editor_v3/css/components/*
import './css/components/advanced_edit_panel.css'
import './css/components/admin_elements_panel.css'
import './css/components/base_element_component.css'
import './css/components/backgrounds_panel.css'
import './css/components/base_edit_panel.css'
import './css/components/base_gallery_panel.css'
import './css/components/base_tab_content_panel.css'
import './css/components/broken_image_modal.css'
import './css/components/calendar_edit_panel.css'
import './css/components/color_picker_modal.css'
import './css/components/coordinate_input.css'
import './css/components/cut_prints.css'
import './css/components/dates_edit_panel.css'
import './css/components/element_icon.css'
import './css/components/drag_and_drop_to_page_mixin.css'
import './css/components/header.css'
import './css/components/image_edit_panel.css'
import './css/components/image_import_panel.css'
import './css/components/image_mask_panel.css'
import './css/components/image_upload_progress_indicator.css'
import './css/components/inline_page_mask_panel.css'
import './css/components/view_settings_panel.css'
import './css/components/layouts_panel.css'
import './css/components/mapped_preview_panel.css'
import './css/components/mobile_autofill_panel.css'
import './css/components/mobile_header.css'
import './css/components/mobile_cut_print_quantity_panel.css'
import './css/components/mobile_image_selector_panel.css'
import './css/components/mobile_page_display.css'
import './css/components/mobile_page_list.css'
import './css/components/mobile_base_edit_panel.css'
import './css/components/mobile_image_edit_tools.css'
import './css/components/mobile_text_edit_tools.css'
import './css/components/mobile_layouts_panel.css'
import './css/components/mobile_sidebar_panel.css'
import './css/components/mobile_toolbar.css'
import './css/components/notification.css'
import './css/components/options_panel.css'
import './css/components/organize_pages.css'
import './css/components/page.css'
import './css/components/page_display.css'
import './css/components/page_mask_panel.css'
import './css/components/page_navigation.css'
import './css/components/page_settings_panel.css'
import './css/components/palette_color_picker.css'
import './css/components/pdf_import_modal.css'
import './css/components/project_gallery_panel.css'
import './css/components/qr_uploader.css'
import './css/components/score_element.css'
import './css/components/spinner_modal.css'
import './css/components/text_alignment_buttons.css'
import './css/components/text_edit_modal.css'
import './css/components/text_edit_panel.css'
import './css/components/upload_method_modal.css'
import './css/components/upload_project_images_modal.css'
import './css/components/uploads_in_progress_modal.css'
import './css/components/user_gallery_panel.css'
import './css/components/vertical_tabbed_menu.css'
