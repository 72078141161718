Px.Editor.AdminElementsPanel = class AdminElementsPanel extends Px.Editor.BaseComponent {

  template() {
    const r = this.renderChild;

    return Px.template`
      <div class="px-admin-elements-panel px-tab-content-panel px-edit-panel">
        ${Px.if(this.displayedSection === 'add-elements', () => {
          return Px.template`
            <div class="px-panel-top-section">
              <h1>${Px.t('Add Elements:')}</h1>
            </div>

            <div class="px-edit-section">
              <div class="px-edit-controls">
                <div class="px-edit-control px-add-element-buttons">
                  <button data-onclick="addInlinePage">
                    ${Px.t('Add Inline Page')}
                  </button>
                  <button data-onclick="addBarcode">
                    ${Px.t('Add Barcode')}
                  </button>
                </div>
              </div>
            </div>
          `;
        }).elseIf(this.displayedSection === 'pdf-edit', () => {
          return Px.template`
            <div class="px-panel-top-section">
              <h1>${Px.t('Edit PDF:')}</h1>
            </div>
            ${r(Px.Editor.PdfEditAccordion, 'pdf-edit-accordion', {pdf: this.selectedPdf})}
          `;
        }).elseIf(this.displayedSection === 'barcode-edit', () => {
          return Px.template`
            <div class="px-panel-top-section">
              <h1>${Px.t('Edit Barcode:')}</h1>
            </div>
            ${r(Px.Editor.BarcodeEditAccordion, 'barcode-edit-accordion', {barcode: this.selectedBarcode})}
          `;
        }).elseIf(this.displayedSection === 'inline-page-edit', () => {
          return Px.template`
            <div class="px-panel-top-section">
              <h1>${Px.t('Edit Inline Page:')}</h1>
            </div>
            ${r(Px.Editor.InlinePageEditAccordion, 'inline-page-edit-accordion', {ipage: this.selectedInlinePage})}
          `;
        }).elseIf(this.displayedSection === 'inline-page-mask', () => {
          return Px.template`
            <div class="px-panel-top-section">
              <h1>
                ${Px.t('Inline Page Mask:')}
                <button data-onclick="backToIPageEdit">
                  ${Px.raw(Px.Editor.BaseGalleryPanel.icons.action_back)}
                  ${Px.t('Back')}
                </button>
              </h1>
            </div>
            ${r(Px.Editor.InlinePageMaskPanel, 'ipage-mask-panel')}
          `;
        }).elseIf(this.displayedSection === 'group-edit', () => {
          return Px.template`
            <div class="px-panel-top-section">
              <h1>${Px.t('Edit Group:')}</h1>
            </div>
            ${r(Px.Editor.GroupEditAccordion, 'group-edit-accordion', {group: this.selectedGroup})}
          `;
        }).elseIf(this.displayedSection === 'calendar-edit', () => {
          return Px.template`
            <div class="px-panel-top-section">
              <h1>${Px.t('Edit Calendar:')}</h1>
            </div>
            ${r(Px.Editor.CalendarEditAccordion, 'calendar-edit-accordion', {calendar: this.selectedCalendar})}
          `;
        }).elseIf(this.displayedSection === 'selection-edit', () => {
          return Px.template`
            <div class="px-panel-top-section">
              <h1>${Px.t('Grouping:')}</h1>
              <div class="px-group-button">
                <button class="px-small px-secondary-color" data-onclick="createGroupFromSelection"
                        ${this.currentSelection.elements.length > 1 ? '' : 'disabled'}>
                  ${Px.t('Group Elements')}
                </button>
              </div>
            </div>
          `;
        })}
      </div>
    `;
  }

  get dataProperties() {
    return {
      store: {required: true}
    };
  }

  static get computedProperties() {
    return {
      displayedSection: function() {
        const store = this.data.store;
        const section = store.ui.admin_elements_tab_section;
        if ((section === 'pdf-edit' && !this.selectedPdf) ||
            (section === 'barcode-edit' && !this.selectedBarcode) ||
            (section === 'inline-page-edit' && !this.selectedInlinePage) ||
            (section === 'group-edit' && !this.selectedGroup) ||
            (section === 'calendar-edit' && !this.selectedCalendar) ||
            (section === 'selection-edit' && !this.currentSelection)) {
          return 'add-elements';
        }
        return section;
      },
      selectedPdf: function() {
        const selected_element = this.data.store.selected_element;
        if (selected_element && selected_element.type === 'pdf') {
          return selected_element;
        }
      },
      selectedBarcode: function() {
        const selected_element = this.data.store.selected_element;
        if (selected_element && selected_element.type === 'barcode') {
          return selected_element;
        }
      },
      selectedInlinePage: function() {
        const selected_element = this.data.store.selected_element;
        if (selected_element && selected_element.type === 'ipage') {
          return selected_element;
        }
      },
      selectedGroup: function() {
        const selected_element = this.data.store.selected_element;
        if (selected_element && selected_element.type === 'group') {
          return selected_element;
        }
      },
      selectedCalendar: function() {
        const selected_element = this.data.store.selected_element;
        if (selected_element && selected_element.type === 'calendar') {
          return selected_element;
        }
      },
      currentSelection: function() {
        const selected_element = this.data.store.selected_element;
        if (selected_element && selected_element.type === 'selection') {
          return selected_element;
        }
      }
    };
  }

  // --------------
  // Event handlers
  // --------------

  addBarcode(evt) {
    this.withUndo('add barcode', () => {
      const store = this.data.store;
      const barcode = store.addBarcode();
      store.selectElement(barcode);
    });
  }

  addInlinePage(evt) {
    this.makeModal(Px.Editor.AdminElementsPanel.InlinePageModal, {
      store: this.data.store,
      title: Px.t('Add Inline Page'),
      onConfirm: this._addInlinePage.bind(this)
    });
  }

  createGroupFromSelection(evt) {
    this.withUndo('create element group', () => {
      const store = this.data.store;
      const elements = store.selected_element.elements;
      const group = store.addGroup();
      elements.forEach(element => store.groupElement(element, group));
      store.selectElement(group);
    });
  }

  backToIPageEdit(evt) {
    mobx.runInAction(() => {
      this.data.store.ui.setAdminElementsTabSection('inline-page-edit');
    });
  }

  // -------
  // Private
  // -------

  _addInlinePage(template_name, cropping) {
    this.withUndo('add inline page', () => {
      if (!template_name) {
        return;
      }
      const store = this.data.store;
      const embedded_page = store.project.pages.find(page => page.name === template_name);
      const props = {
        template: template_name
      };
      if (embedded_page) {
        const pw = store.selected_page.width;
        const ph = store.selected_page.height;
        let source_width = embedded_page.width;
        let source_height = embedded_page.height;
        let crop = false;
        let zoom = 0;
        if (cropping.some(c => c !== 0)) {
          let zoom_width = source_width;
          let zoom_height = source_height
          source_width -= (cropping[1] + cropping[3]);
          source_height -= (cropping[0] + cropping[2]);
          zoom = 100 * (Math.min(zoom_width/source_width, zoom_height/source_height) - 1);
          crop = true;
        }
        const aspect_ratio = source_width / source_height;
        const dims = Px.Util.inscribedRectangleDimensions(pw / 1.5, ph / 1.5, 0, aspect_ratio);
        props.width = dims.width;
        props.height = dims.height;
        props.crop = crop;
        props.zoom = zoom;
      }
      const ipage = store.addInlinePage(props);
      store.selectElement(ipage);
    });
  }

};

Px.Editor.AdminElementsPanel.InlinePageModal = class InlinePageModal extends Px.Components.BaseModal {

  get content() {
    return Px.template`
      <div class="px-modal-control">
        <label>${Px.t('Template Name')}</label>
        ${this.renderChild(Px.Components.Dropdown, 'page-name-dropdown', this.pageNameDropdownProps)}
      </div>

      ${Px.if(this.state.selected_template_name, () => {
        return Px.template`
          <div class="px-modal-control">
            <label>Cropping</label>
            ${this.renderChild(Px.Components.Dropdown, 'blee-options-dropdown', this.bleedOptionsDropdownProps)}
          </div>

          ${Px.if(this.state.selected_crop_option === 'custom', () => {
            const unit = this.data.store.project.unit === 'inch' ? 'in' : 'mm';
            return Px.template`
              <div class="px-modal-control">
                <label>Custom cropping</label>
                <div class="px-crop-inputs">
                  <div>
                    <label>top (${unit}):</label>
                    <input type="number" step="0.001" min="0" data-onchange="setCustomCrop"
                            value="${this.state.custom_crop_top}" data-side="top" />
                  </div>
                  <div>
                    <label>right (${unit}):</label>
                    <input type="number" step="0.001" min="0" data-onchange="setCustomCrop"
                            value="${this.state.custom_crop_right}" data-side="right" />
                  </div>
                  <div>
                    <label>bottom (${unit}):</label>
                    <input type="number" step="0.001" min="0" data-onchange="setCustomCrop"
                            value="${this.state.custom_crop_bottom}" data-side="bottom" />
                  </div>
                  <div>
                    <label>left (${unit}):</label>
                    <input type="number" step="0.001" min="0" data-onchange="setCustomCrop"
                            value="${this.state.custom_crop_left}" data-side="left" />
                  </div>
                </div>
              </div>
            `;
          })}
        `;
      })}
    `;
  }

  get footer() {
    return Px.template`
      <div>
        <button class="px-large px-primary-color px-strong"
                data-onclick="onConfirm"
                ${this.state.selected_template_name ? '' : 'disabled'}>
          ${Px.t('Add Inline Page')}
        </button>
      </div>
    `;
  }

  get css_class() {
    return `${super.css_class} px-inline-page-modal`;
  }

  get dataProperties() {
    return {
      store: {required: true},
      onConfirm: {required: true}
    };
  }

  static get properties() {
    return {
      selected_template_name: {std: ''},
      selected_crop_option: {std: ''},
      custom_crop_top: {std: 0},
      custom_crop_right: {std: 0},
      custom_crop_bottom: {std: 0},
      custom_crop_left: {std: 0}
    };
  }

  constructor(data) {
    super(data);

    this.registerReaction(() => this.state.selected_template_name, () => {
      const selected_crop = this.state.selected_crop_option;
      if (!(selected_crop === 'custom' || this.availableBleedValues.includes(selected_crop))) {
        this.state.selected_crop_option = '';
      }
    });
  }

  static get computedProperties() {
    return {
      pageNameDropdownProps: function() {
        const selected_page = this.data.store.selected_page;
        const template_names = [];
        this.data.store.theme.templates.forEach(template => {
          if (template.name !== selected_page.name) {
            template_names.push(template.name);
          }
        });
        const options = [{name: '-- Select template --', value: ''}];
        _.uniq(template_names).forEach(name => {
          options.push({name: name, value: name});
        });
        return {
          value: this.state.selected_template_name,
          options: options,
          onNewValue: name => this.state.selected_template_name = name
        };
      },
      bleedOptionsDropdownProps: function() {
        const options = [{name: '-- No cropping --', value: ''}];
        this.availableBleedValues.filter(v => v !== 0).forEach(bleed => {
          options.push({name: `Crop bleed (${this.formatInUserUnits(bleed)})`, value: bleed});
        });
        options.push({name: 'Custom cropping', value: 'custom'});
        return {
          value: this.state.selected_crop_option,
          options: options,
          onNewValue: value => this.state.selected_crop_option = value
        }
      },
      availableBleedValues: function() {
        const store = this.data.store;
        const template = store.theme.templates.find(template => template.name === this.state.selected_template_name);
        return store.project.availableBleedValuesForPage(template.page).filter(v => v !== 0);
      },
      cropValues: function() {
        let result;
        const selected_crop = this.state.selected_crop_option.toString();
        if (!selected_crop) {
          result = [0, 0, 0, 0];
        } else if (selected_crop === 'custom') {
          let values = [
            this.state.custom_crop_top,
            this.state.custom_crop_right,
            this.state.custom_crop_bottom,
            this.state.custom_crop_left
          ].map(v => parseFloat(v));
          if (this.data.store.project.unit === 'inch') {
            values = values.map(v => Px.Util.in2mm(v));
          }
          result = values;
        } else {
          const values = selected_crop.split(' ').map(v => parseFloat(v.trim()));
          if (values.length === 1) {
            result = [values[0], values[0], values[0], values[0]];
          } else if (values.length === 2) {
            result = [values[0], values[1], values[0], values[1]];
          } else if (values.length === 3) {
            result = [values[0], values[1], values[2], values[1]];
          } else {
            result = [values[0], values[1], values[2], values[3]];
          }
        }
        return result;
      }
    };
  }

  // --------------
  // Event handlers
  // --------------

  setCustomCrop(evt) {
    const side = evt.target.getAttribute('data-side');
    const value = parseFloat(evt.target.value);
    switch (side) {
    case 'top':
      this.state.custom_crop_top = value;
      break;
    case 'right':
      this.state.custom_crop_right = value;
      break;
    case 'bottom':
      this.state.custom_crop_bottom = value;
      break;
    case 'left':
      this.state.custom_crop_left = value;
      break;
    }
  }

  onConfirm(evt) {
    this.data.onConfirm(
      this.state.selected_template_name,
      this.cropValues
    );
    this.destroy();
  }

  // -------
  // Private
  // -------

  formatInUserUnits(val) {
    val = this.toUserUnits(val);
    const unit = this.data.store.project.unit === 'mm' ? 'mm' : 'in';
    return `${val} ${unit}`;
  }

  toUserUnits(val) {
    if (this.data.store.project.unit === 'inch') {
      return Px.Util.mm2in(val);
    }
    return val;
  }

};
