Px.Editor.UIStore = class UIStore extends Px.BaseStore {

  constructor(main_store) {
    super();

    this.main_store = main_store;
    this.previous_selected_element = null;

    // Clear state every time selected_element changes.
    // Note that this reaction may run *after* the UI has already been redrawn,
    // so we can't assume the state will always be clean when the UI is refreshed.
    mobx.reaction(() => this.selected_element, element => this.onNewSelectedElement(element), {
      name: 'Px.Editor.UIStore::NewElementSelectedReaction'
    });

    mobx.reaction(() => [this.editor_mode, this.main_store.image_sources], ([mode, sources]) => {
      this.active_image_source = mode === 'mobile' ? null : (sources[0] || null);
    }, {
      name: 'Px.Editor.UIStore::DefaultImageSourceReaction',
      fireImmediately: true
    });
  }

  static get properties() {
    return {
      editor_mode: {std: 'desktop'},
      main_view: {std: 'edit-pages'},
      // Tabs & panels
      _expanded_tab_id: {std: null},
      _image_tab_section: {std: 'no-image-notice'},
      _admin_elements_tab_section: {std: 'add-elements'},
      active_image_source: {std: null},
      sidebar_item_size: {std: 'medium'},
      // Page display area view settings
      page_display_zoom: {std: 1},
      show_element_selection_outline: {std: true},
      show_element_resize_handles: {std: true},
      show_grid: {std: false},
      grid_hblocks: {std: 4},
      grid_vblocks: {std: 4},
      // Transient data (drags etc.)
      current_drag: {std: null},
      is_zooming: {std: false},
      _preview_mode: {std: false},
      _element_cropping_mode: {std: false},
      image_swap_source: {std: null},
      // Window dimensions
      window_width: {std: 0},
      window_height: {std: 0}
    };
  }

  static get computedProperties() {
    return {
      selected_element: function() {
        return this.main_store.selected_element;
      },
      preview_mode: function() {
        return this._preview_mode || this.share_view;
      },
      element_cropping_mode: function() {
        if (!this.selected_element) {
          return null;
        }
        if (this.editor_mode === 'mobile') {
          return this.main_store.mobile.active_tool === 'image-crop-editor';
        } else {
          return this._element_cropping_mode;
        }
      },
      image_swap_mode: function() {
        return Boolean(this.image_swap_source);
      },
      image_tab_section: function() {
        var section = this._image_tab_section;
        if (section === 'image-edit' && !this.selected_element) {
          return 'no-image-notice';
        }
        return section;
      },
      admin_elements_tab_section: function() {
        var section = this._admin_elements_tab_section;
        if ((section === 'barcode-edit' && !(this.selected_element && this.selected_element.type === 'barcode')) ||
            (section === 'inline-page-edit' && !(this.selected_element && this.selected_element.type === 'ipage')) ||
            (section === 'inline-page-mask' && !(this.selected_element && this.selected_element.type === 'ipage')) ||
            (section === 'group-edit' && !(this.selected_element && this.selected_element.type === 'group')) ||
            (section === 'calendar-edit' && !(this.selected_element && this.selected_element.type === 'calendar')) ||
            (section === 'selection-edit' && !(this.selected_element && this.selected_element.type === 'selection'))) {
          return 'add-elements';
        }
        return section;
      },
      share_view: function() {
        return Boolean(Px.config.share_code);
      },
      grid_spec: function() {
        if (this.show_grid) {
          return {
            vblocks: this.grid_vblocks,
            hblocks: this.grid_hblocks
          };
        } else {
          return {
            vblocks: 1,
            hblocks: 1
          };
        }
      }
    };
  }

  get actions() {
    return {
      onNewSelectedElement: function(element) {
        const previous_element = this.previous_selected_element;
        this.previous_selected_element = element;
        if (element && element.two_page_spread_clone && element.two_page_spread_clone === previous_element) {
          // If the new selected element is the clone of the previously selected element,
          // don't do anyting since we're still dealing with the same element from the user's perspective.
          return;
        }
        if (this.editor_mode !== 'mobile') {
          // Disable image swap mode if:
          // - a different element gets selected
          // - no element is selected, and we are still on the same set
          if (this.image_swap_mode) {
            if ((element &&
                 element !== this.image_swap_source &&
                 element !== this.image_swap_source.two_page_spread_clone) ||
                ((element === null &&
                  this.image_swap_source.page.set === this.main_store.selected_set))) {
              this.image_swap_source = null;
            }
          }
          this._element_cropping_mode = false;
          if (element) {
            if (element.type === 'image') {
              this.expandTab('images');
              this.setImageTabSection('image-edit');
            } else if (element.type === 'text') {
              this.expandTab('texts');
            } else if (Px.config.advanced_edit_mode &&
                       (['pdf', 'barcode', 'ipage', 'group', 'calendar', 'selection'].includes(element.type))) {
              this.expandTab('admin_elements');
              if (element.type === 'pdf') {
                this.setAdminElementsTabSection('pdf-edit');
              } else if (element.type === 'barcode') {
                this.setAdminElementsTabSection('barcode-edit');
              } else if (element.type === 'ipage') {
                this.setAdminElementsTabSection('inline-page-edit');
              } else if (element.type === 'group') {
                this.setAdminElementsTabSection('group-edit');
              } else if (element.type === 'calendar') {
                this.setAdminElementsTabSection('calendar-edit');
              } else if (element.type === 'selection') {
                this.setAdminElementsTabSection('selection-edit');
              }
            }
          } else {
            if (this.image_tab_section === 'image-edit') {
              this.setImageTabSection('no-image-notice');
            }
            if (this.admin_elements_tab_section === 'pdf-edit' ||
                this.admin_elements_tab_section === 'group-edit' ||
                this.admin_elements_tab_section === 'group-edit' ||
                this.admin_elements_tab_section === 'calendar-edit' ||
                this.admin_elements_tab_section === 'selection-edit') {
              this.setAdminElementsTabSection('add-elements');
            }
            if (this.main_store.cut_print_mode && this.main_store.selected_set === null) {
              this.expandTab(null);
            }
          }
        }
      },

      setEditorMode: function(mode) {
        if (!_.include(UIStore.EDITOR_MODES, mode)) {
          throw new Error('Unrecognized editor mode: ' + mode);
        }
        this.editor_mode = mode;
      },

      setMainView: function(view) {
        if (!_.include(UIStore.MAIN_VIEWS, view)) {
          throw new Error('Unrecognized view name: ' + view);
        }
        this.main_view = view;
      },

      expandTab: function(tab_id) {
        if (tab_id !== null && !_.include(UIStore.TAB_IDS, tab_id)) {
          throw new Error('Unrecognized tab id: ' + tab_id);
        }
        this._expanded_tab_id = tab_id;
      },

      setImageTabSection: function(section) {
        if (!_.include(UIStore.IMAGE_TAB_SECTIONS, section)) {
          throw new Error('Unrecognized image tab section: ' + section);
        }
        this._image_tab_section = section;
      },

      setAdminElementsTabSection: function(section) {
        if (!_.include(UIStore.ADMIN_ELEMENTS_TAB_SECTIONS, section)) {
          throw new Error('Unrecognized admin elements tab section: ' + section);
        }
        this._admin_elements_tab_section = section;
      },

      togglePreviewMode: function() {
        this._preview_mode = !this._preview_mode;
      },

      toggleElementCroppingMode: function(val) {
        if (typeof val !== 'undefined') {
          this._element_cropping_mode = val;
        } else {
          this._element_cropping_mode = !this._element_cropping_mode;
        }
      },

      enableImageSwapMode: function(element) {
        this.image_swap_source = element;
      },

      disableImageSwapMode: function() {
        this.image_swap_source = null;
      },

      setZoom: function(value) {
        const min = UIStore.ZOOM.MIN;
        const max = this.editor_mode === 'mobile' ? UIStore.ZOOM.MAX_MOBILE : UIStore.ZOOM.MAX;
        this.page_display_zoom = Math.max(min, Math.min(max, value));
      },

      zoomIn: function(amount) {
        this.setZoom(this.page_display_zoom + amount);
      },

      resetZoom: function() {
        this.page_display_zoom = 1;
      },

      startDrag: function(element, pageX, pageY) {
        this.current_drag = {
          origin: {
            pageX: pageX,
            pageY: pageY,
            element_x: element.x,
            element_y: element.y,
            element_width: element.width,
            element_height: element.height,
            element_left: element.left,
            element_top: element.top,
            element_rotation: element.rotation
          }
        };
      },

      stopDrag: function() {
        this.current_drag = null;
      }
    };
  }

  // ---------------
  // Getters/setters
  // ---------------

  get expanded_tab_id() {
    if (this.main_view === 'edit-pages') {
      return this._expanded_tab_id;
    } else {
      return null;
    }
  }

  // -------
  // Private
  // -------

  isClipart(element) {
    return this.main_store.galleries.clipart.images.includes(element.id);
  }

};


Px.Editor.UIStore.EDITOR_MODES = ['desktop', 'mobile'];
Px.Editor.UIStore.MAIN_VIEWS = ['edit-pages', 'organize-pages'];
Px.Editor.UIStore.TAB_IDS = [
  'images', 'texts', 'layouts', 'clipart', 'background',
  'view_settings', 'options', 'mask', 'admin_elements', 'page_settings'
];
Px.Editor.UIStore.IMAGE_TAB_SECTIONS = [
  'no-image-notice', 'image-edit', 'image-mask'
];
Px.Editor.UIStore.ADMIN_ELEMENTS_TAB_SECTIONS = [
  'add-elements', 'pdf-edit', 'barcode-edit',
  'inline-page-edit', 'inline-page-mask',
  'group-edit', 'calendar-edit', 'selection-edit'
];
Px.Editor.UIStore.ZOOM = {
  MIN: 1,
  MAX: 3,
  MAX_MOBILE: 5
};
