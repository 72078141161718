Px.Editor.ElementResizeHandles = class ElementResizeHandles extends Px.Util.mixin(
  Px.Editor.BaseComponent,
  Px.Editor.SVGElementMixin
) {
  template() {
    return Px.template`
      <g class="px-control-component"
         opacity="${this.isEnabled ? 1 : 0}"
         pointer-events="${this.pointerEventsAttribute}">
        ${ElementResizeHandles.handles.map((handle, idx) => {
          const size = idx % 2 === 0 ? this.handleSize : (this.handleSize * 0.67);
          return Px.template`
            <rect width="${size}"
                  height="${size}"
                  x="${(handle.x * this.data.element.width) - (size/2)}"
                  y="${(handle.y * this.data.element.height) - (size/2)}"
                  stroke-width="${this.strokeWidth}"
                  stroke="var(--editor-selection-color)"
                  fill="#fff"
            />
            <rect class="px-control-handle px-resize-handle"
                  width="${this.paddedSize(handle.position)}"
                  height="${this.paddedSize(handle.position)}"
                  x="${(handle.x * this.data.element.width) - (this.paddedSize(handle.position)/2)}"
                  y="${(handle.y * this.data.element.height) - (this.paddedSize(handle.position)/2)}"
                  opacity="0"
                  fill-opacity="0"
                  cursor="${this.cursor(idx)}-resize"
                  data-position="${handle.position}"
                  data-onclick="onClick"
                  data-onmousedown="grabHandle"
                  data-ontouchstart="grabHandle"
            />
          `;
        })}
      </g>
    `;
  }

  constructor(props) {
    super(props);
    this._drag_raf = null;
    this.dragHandle = this.dragHandle.bind(this);
    this.releaseHandle = this.releaseHandle.bind(this);
  }

  get dataProperties() {
    return {
      element: {required: true},
      scale: {required: true},
      store: {required: true},
      handle_size: {std: 13},
      handle_padding: {std: 8}
    }
  }

  static get properties() {
    return {
      active_handle: {type: 'str', std: null}
    }
  }

  static get computedProperties() {
    return {
      isEnabled: function() {
        const element = this.data.element;
        const selected_element = this.data.store.selected_element;
        if (!(selected_element && selected_element.edit &&
              (element.resize || (element.type === 'barcode' && Px.config.advanced_edit_mode)))) {
          return false;
        }
        return element === selected_element || element.two_page_spread_clone === selected_element;
      },
      pointerEventsAttribute: function() {
        return this.isEnabled ? 'auto' : 'none';
      },
      expandedPadding: function() {
        const page = this.data.element.page;
        return Math.max(page.width, page.height);
      },
      handleSize: function() {
        return this.inSvgUnits(this.data.handle_size);
      },
      strokeWidth: function() {
        return this.inSvgUnits(1);
      },
      alignmentSnapTolerance: function() {
        return this.inSvgUnits(Px.Editor.BaseElementModel.ELEMENT_ALIGNMENT_SNAP_TOLERANCE_PIXELS);
      }
    };
  }

  // Active handle (handle currently being dragged) should expand to cover all page.
  // This is so that releasing the mouse anywhere on the page does not trigger page
  // onClick event, which by default deselects the currently selected element.
  padding(handle_position) {
    if (this.state.active_handle === handle_position) {
      return this.expandedPadding;
    } else {
      return this.data.handle_padding;
    }
  }

  paddedSize(handle_position) {
    const padding = this.padding(handle_position);
    return this.inSvgUnits(this.data.handle_size + (2 * padding));
  }

  cursor(handle_idx) {
    const store = this.data.store;
    const element = this.data.element;
    const cursors = ['nwse', 'ns', 'nesw', 'ew', 'nwse', 'ns', 'nesw', 'ew'];
    const rotation_offset = Math.round(this.data.element.rotation / 45);
    const autorotation_offset = store.isAutorotatedCutPrint(element.page) ? 2 : 0;
    let offset = (rotation_offset + autorotation_offset + handle_idx) % cursors.length;
    if (offset < 0) {
      offset += cursors.length;
    }
    return cursors[offset];
  }

  // --------------
  // Event handlers
  // --------------

  // Prevent click from propagating to the page.
  onClick(evt) {
    evt.stopPropagation();
  }

  grabHandle(evt) {
    if (evt.type === 'mousedown' && evt.which !== 1) {
      return;
    }

    evt.stopPropagation();
    evt.preventDefault();

    const element = this.data.element;
    const pageX = 'pageX' in evt ? evt.pageX : evt.targetTouches[0].pageX;
    const pageY = 'pageY' in evt ? evt.pageY : evt.targetTouches[0].pageY;

    mobx.runInAction(() => {
      this.data.store.startResizingElement(element, pageX, pageY);
      this.state.active_handle = evt.target.getAttribute('data-position');
    });

    this._end_with_undo = this.data.store.undo_redo.beginWithUndo({
      label: 'resize ' + element.type,
      set_id: this.data.store.selected_set.id
    });

    const $doc = $j(document);
    $doc.on('mousemove touchmove', this.dragHandle);
    $doc.on('mouseup touchend touchcancel', this.releaseHandle);
  }

  dragHandle(evt) {
    if (this._drag_raf) {
      cancelAnimationFrame(this._drag_raf);
    }
    this._drag_raf = requestAnimationFrame(() => {
      const pageX = 'pageX' in evt ? evt.pageX : evt.targetTouches[0].pageX;
      const pageY = 'pageY' in evt ? evt.pageY : evt.targetTouches[0].pageY;

      const element = this.data.element;
      const drag_origin = this.data.store.ui.current_drag.origin;
      const old_width = drag_origin.element_width;
      const old_height = drag_origin.element_height;

      let dx = this.inSvgUnits(pageX - drag_origin.pageX);
      let dy = this.inSvgUnits(pageY - drag_origin.pageY);

      if (this.data.store.isAutorotatedCutPrint(element.page)) {
        // Swap dx and dy.
        [dx, dy] = [-dy, dx];
      }

      const d_rotated = Px.Util.rotatePoint(dx, dy, element.absolute_rotation);
      let dw = d_rotated[0];
      let dh = d_rotated[1];

      const active_handle = this.state.active_handle;
      if (active_handle === 'top') {
        dh = -dh;
        dw = 0;
      } else if (active_handle === 'bottom') {
        dw = 0;
      } else if (active_handle === 'left') {
        dw = -dw;
        dh = 0;
      } else if (active_handle === 'right') {
        dh = 0;
      } else {
        if (active_handle === 'top-left') {
          dh = -dh;
          dw = -dw;
        } else if (active_handle === 'top-right') {
          dh = -dh;
        } else if (active_handle === 'bottom-left') {
          dw = -dw;
        }
        // Maintain aspect ratio.
        const sw = dw / old_width;
        const sh = dh / old_height;
        if (sw > sh) {
          dh = sw * old_height;
        } else {
          dw = sh * old_width;
        }
      }

      // Width/height are enforced to never be less than zero.
      let new_width = Math.max(0, old_width + dw);
      let new_height = Math.max(0, old_height + dh);
      dw = new_width - old_width;
      dh = new_height - old_height;

      let shift_x = dw;
      let shift_y = dh;

      if (active_handle === 'top') {
        shift_y -= 2*dh;
      } else if (active_handle === 'left') {
        shift_x -= 2*dw;
      } else if (active_handle === 'top-left') {
        shift_x -= 2*dw;
        shift_y -= 2*dh;
      } else if (active_handle === 'top-right') {
        shift_y -= 2*dh;
      } else if (active_handle === 'bottom-left') {
        shift_x -= 2*dw;
      }

      // Owner's rotation compared to its container
      // (we aren't interested in the total rotation anymore,
      // because here we are not dealing with mouse coordinates).
      const shift_rotated = Px.Util.rotatePoint(shift_x, shift_y, -element.rotation);
      let new_x = drag_origin.element_x + (shift_rotated[0] - dw)/2;
      let new_y = drag_origin.element_y + (shift_rotated[1] - dh)/2;

      const tolerance = this.alignmentSnapTolerance;
      const snap_point = element.resizingSnapPoint(
        new_x, new_y, new_width, new_height, active_handle, this.data.store.ui.grid_spec
      );

      switch (active_handle) {
      case 'top-left':
        if ((snap_point.x !== null && Math.abs(snap_point.x - new_x) < tolerance) ||
            (snap_point.y !== null && Math.abs(snap_point.y - new_y) < tolerance)) {
          new_x = snap_point.x;
          new_y = snap_point.y;
          new_width = snap_point.width;
          new_height = snap_point.height;
        }
        break;
      case 'top':
        if (snap_point.y !== null && Math.abs(snap_point.y - new_y) < tolerance) {
          new_y = snap_point.y;
          new_height = snap_point.height;
        }
        break;
      case 'top-right':
        if ((snap_point.width !== null && Math.abs(snap_point.width - new_width) < tolerance) ||
            (snap_point.y !== null && Math.abs(snap_point.y - new_y) < tolerance)) {
          new_width = snap_point.width;
          new_height = snap_point.height;
          new_y = snap_point.y;
        }
        break;
      case 'right':
        if (snap_point.width !== null && Math.abs(snap_point.width - new_width) < tolerance) {
          new_width = snap_point.width;
        }
        break;
      case 'bottom-right':
        if ((snap_point.width !== null && Math.abs(snap_point.width - new_width) < tolerance) ||
            (snap_point.height !== null && Math.abs(snap_point.height - new_height) < tolerance)) {
          new_width = snap_point.width;
          new_height = snap_point.height;
        }
        break;
      case 'bottom':
        if (snap_point.height !== null && Math.abs(snap_point.height - new_height) < tolerance) {
          new_height = snap_point.height;
        }
        break;
      case 'bottom-left':
        if ((snap_point.width !== null && Math.abs(snap_point.width - new_width) < tolerance) ||
            (snap_point.height !== null && Math.abs(snap_point.height - new_height) < tolerance)) {
          new_width = snap_point.width;
          new_height = snap_point.height;
          new_x = snap_point.x;
        }
        break;
      case 'left':
        if (snap_point.x !== null && Math.abs(snap_point.x - new_x) < tolerance) {
          new_x = snap_point.x;
          new_width = snap_point.width;
        }
        break;
      }

      element.update({
        x: new_x,
        y: new_y,
        width: new_width,
        height: new_height
      });
    });
  }

  releaseHandle(evt) {
    if (this._drag_raf) {
      cancelAnimationFrame(this._drag_raf);
      this._drag_raf = null;
    }
    const $doc = $j(document);
    $doc.off('mousemove touchmove', this.dragHandle);
    $doc.off('mouseup touchend touchcancel', this.releaseHandle);
    mobx.runInAction(() => {
      this.data.store.stopResizingElement(this.data.element);
      this.state.active_handle = null;
    });
    this._end_with_undo();
  }

};

Px.Editor.ElementResizeHandles.handles = [
  {position: 'top-left', x: 0, y: 0},
  {position: 'top', x: 0.5, y: 0},
  {position: 'top-right', x: 1, y: 0},
  {position: 'right', x: 1, y: 0.5},
  {position: 'bottom-right', x: 1, y: 1},
  {position: 'bottom', x: 0.5, y: 1},
  {position: 'bottom-left', x: 0, y: 1},
  {position: 'left', x: 0, y: 0.5}
];
