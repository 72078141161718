Px.Editor.BookProjectStore = class BookProjectStore extends Px.Editor.BaseProjectStore {

  constructor(project_id, main_store) {
    super();
    this.project_id = project_id;
    this.share_code = main_store.share_code;

    this.main_store = main_store;
    this.theme_store = main_store.theme;
    this.layout_store = main_store.layouts;
    this.image_store = main_store.images;
    this.pdf_store = main_store.pdfs;
    this.option_store = main_store.options;
  }

  static get properties() {
    return Object.assign(super.properties, {
      build_parameters: {std: mobx.observable.map()},
      // Layout snapshot currently stored on the server. We use this to calculate the differences
      // compared to the stored version next time we want to save the project.
      _saved_options: {std: mobx.observable.map()},
      _saved_template_options: {std: mobx.observable.map()}
    });
  }

  static get computedProperties() {
    return Object.assign(super.computedProperties, {
      growable_sets: function() {
        var sets = [];
        this.page_sets.forEach(function(set) {
          if (set.grow) {
            sets.push(set);
          }
        });
        return sets;
      },
      last_growable_set: function() {
        return this.growable_sets[this.growable_sets.length - 1] || null;
      },
      last_fixed_start_set: function() {
        let last_def = null;
        for (const set of this.theme_store.set_definitions) {
          if (set.grow) {
            break;
          }
          last_def = set;
        }
        for (const set_with_def of this.sets_with_definitions) {
          if (set_with_def.definition === last_def) {
            return set_with_def.set;
          }
        }
        return null;
      },
      set_definitions_for_project: function() {
        const theme_store = this.theme_store;
        if (!(this.loaded && theme_store.loaded)) {
          return [];
        }
        const setsDefsOneLevel = (structures, base_date) => {
          let set_definitions = []
          structures.forEach(struct => {
            if (struct.type === 'foreachdate') {
              theme_store.generateDates(base_date, struct.dates_name).forEach(date => {
                set_definitions = set_definitions.concat(setsDefsOneLevel(struct.structure, date));
              });
            } else {
              set_definitions.push(struct.set);
            }
          });
          return set_definitions;
        };
        const start_date_str = this.build_parameters.get('start_date');
        let start_date = null;
        if (start_date_str) {
          const parts = start_date_str.split('-').map(s => parseInt(s, 10));
          start_date = new Date(Date.UTC(parts[0], parts[1] - 1, parts[2]));
        }
        return setsDefsOneLevel(theme_store.set_structure, start_date);
      },
      fixed_pages_count: function() {
        let count = 0
        this.set_definitions_for_project.forEach(set_definition => {
          if (set_definition.grow) {
            // The grow set is not a fixed set, so do nothing.
          } else {
            count += set_definition.pages.length;
          }
        });
        return count;
      },
      fixed_pages_at_end_count: function() {
        let grow_set_seen = false
        let count = 0
        this.set_definitions_for_project.forEach(set_definition => {
          if (grow_set_seen) {
            count += set_definition.pages.length;
          }
          if (set_definition.grow) {
            grow_set_seen = true;
          }
        });
        return count;
      },
      sets_with_definitions: function() {
        const theme_store = this.theme_store;
        if (!(this.loaded && theme_store.loaded)) {
          return [];
        }
        const sets_with_definitions = [];
        const sets = this.page_sets;
        let set_idx = 0;
        let number_of_pages = this.pages.length;
        this.set_definitions_for_project.forEach(set_definition => {
          if (set_definition.grow) {
            let i = number_of_pages - this.fixed_pages_count;
            while (i > 0) {
              const set = sets[set_idx];
              sets_with_definitions.push({set: set, definition: set_definition});
              i -= set.pages.length;
              set_idx++;
            }
          } else if (set_idx < sets.length) {
            sets_with_definitions.push({set: sets[set_idx], definition: set_definition});
            set_idx++;
          }
        });
        return sets_with_definitions;
      },
      editor_sets_with_definitions: function() {
        return this.sets_with_definitions.filter(set_with_def => set_with_def.set.editor);
      },
      cut_print_sets_with_definitions: function() {
        const sets_with_defs = [];
        this.editor_sets_with_definitions.forEach(set_with_definition => {
          const image = set_with_definition.set.cut_print_image;
          if (image) {
            sets_with_defs.push({
              set: set_with_definition.set,
              definition: set_with_definition.definition,
              cut_print_count: 1
            });
          }
        });
        return sets_with_defs;
      },
      _pages_with_definitions: function() {
        var pages_with_definitions = [];
        this.sets_with_definitions.forEach(function(set_with_definition) {
          var set = set_with_definition.set;
          var set_definition = set_with_definition.definition;
          for (var i = 0; i < set.pages.length; i++) {
            pages_with_definitions.push({page: set.pages[i], definition: set_definition.pages[i]});
          }
        });
        return pages_with_definitions;
      },
      can_add_pages: function() {
        const theme_store = this.theme_store;
        const increments = theme_store.page_increments;
        return increments > 0 && this.page_count <= (theme_store.max_pages - increments);
      },
      can_delete_pages: function() {
        const theme_store = this.theme_store;
        const increments = theme_store.page_increments;
        return increments > 0 && this.page_count >= (theme_store.min_pages + increments);
      },
      are_options_dirty: function() {
        return !Px.Util.isMapEqual(this.options, this._saved_options);
      },
      are_template_options_dirty: function() {
        return !Px.Util.isMapEqual(this.template_options, this._saved_template_options);
      }
    });
  }

  get actions() {
    return Object.assign(super.actions, {
      load: function() {
        let url = `/v1/books/${this.project_id}.json`;
        if (this.share_code) {
          url += '?' + new URLSearchParams({share: this.share_code});
        }
        const params = this.share_code ? {share: this.share_code} : {};
        const promise = fetch(url).then(response => {
          if (response.ok) {
            return response.json();
          } else {
            const err = new Error(`HTTP Error; Status: ${response.status}`);
            err.http_status = response.status;
            throw err;
          }
        }).then(data => {
          mobx.runInAction(() => {
            this.loaded = true;
            this.name = data.name;
            this.theme_id = data.theme_id;
            this.product_id = data.product_id;
            this.product_name = data.product_name;
            this.unit = data.unit;
            if (data.minimum_dpi) {
              this.minimum_dpi = data.minimum_dpi;
            }
            this.build_parameters.replace(data.build_parameters);
            this.setOptions(data);
            const pdfs = data.pdfs;
            pdfs.forEach(pdf => {
              const pdf_id = `db:${pdf.id}`;
              if (!this.pdf_store.get(pdf_id)) {
                this.pdf_store.register(pdf_id, pdf);
              }
            });
            const images = data.images;
            // We need to loop through images twice - first to register all images with the image store,
            // and second to store all theme asset images.
            // The layout *has* to be built and replaced between the two loops.
            images.forEach(image => {
              const img_id = `db:${image.id}`;
              if (!this.image_store.get(img_id)) {
                this.image_store.register(img_id, image);
              }
            });
            this.setLayoutAndStoreSavedSnapshot(data.layout.pages.set);
          });
        }).catch(err => {
          this.loaded = true;
          if (err.http_status === 403) {
            let default_text = "You don't have permission to open this project.\n";
            default_text += "Perhaps you forgot to log in?";
            return Promise.reject(Px.t('project permission error', default_text));
          } else {
            let default_text = "An error occured while loading the project.\n";
            default_text += "You can try reloading the page.\n";
            default_text += "If the problem persists, please contact support.";
            return Promise.reject(Px.t('project load error', default_text));
          }
        });
        return promise;
      },

      setLayoutAndStoreSavedSnapshot: function(layout_json) {
        this.setLayout(layout_json);
        // Schedule saved_snapshot to be stored after all other already scheduled reactions
        // observing the layout hash already finish running. This is neccessary to make sure
        // element clones and other modifications to the layout that happen as reaction effects
        // when the layout is loaded are already in place before we store the snapshot.
        mobx.when(() => true, () => this.saved_snapshot = this.layout_snapshot, {
          name: 'Px.Editor.BookProjectStore::StoreSavedSnapshotReaction'
        });
      },

      addPages: function(position) {
        let new_pages = [];

        if (this.can_add_pages) {
          const theme_store = this.theme_store;
          const grow_set_definition = theme_store.grow_set_definition;

          if (typeof position !== 'undefined') {
            // Sanity check to verify that we can add sets to the requested position.
            if (position === 0) {
              // We can add it at the start only if there's no fixed set at the beginning of the book.
              if (this.last_fixed_start_set) {
                throw `Cannot add page at position ${position}`;
              }
            } else if (position === this.page_sets.length) {
              // We can add new pages after the last set only if the last set is growable and is already full,
              // or if the last set is the last fixed start set.
              const last_set = this.page_sets[position - 1];
              if (last_set === this.last_fixed_start_set) {
                // all good
              } else if (!(last_set.grow && last_set.pages.length === grow_set_definition.pages.length)) {
                throw `Cannot add page at position ${position}`;
              }
            }
          } else if (this.last_growable_set) {
            if (this.last_growable_set) {
              // If no explicit position is given, add the pages after the last growable set
              // (or into the last growable set, if it's not yet full);
              if (this.last_growable_set.pages.length === grow_set_definition.pages.length) {
                position = this.last_growable_set.position + 1;
              } else {
                position = this.last_growable_set.position;
              }
            } else if (this.last_fixed_start_set) {
              // If no growable set exists, add the pages after the last fixed set.
              position = this.last_fixed_start_set + 1;
            } else {
              // Otherwise add it at the beginning.
              position = 0;
            }
          }

          // If no growable set with capacity exists at the desired position, create it.
          let current_grow_set = (position < this.page_sets.length) ? this.page_sets[position] : null;
          if (!(current_grow_set &&
                current_grow_set.grow &&
                current_grow_set.pages.length < grow_set_definition.pages.length)) {
            current_grow_set = this.makeGrowSet();
            this.page_sets.splice(position, 0, current_grow_set);
          }

          const page_offset = current_grow_set.pages.length;
          new_pages = theme_store.addPages(this.growable_sets);
          // Add new pages to the layout.
          const pages_in_set_count = grow_set_definition.pages.length;
          new_pages.forEach((page) => {
            if (current_grow_set.pages.length >= pages_in_set_count) {
              const current_position = current_grow_set.position;
              current_grow_set = current_grow_set.clone({pages: []});
              this.page_sets.splice(current_position + 1, 0, current_grow_set);
            }
            current_grow_set.addPage(page);
          });

          new_pages.forEach(page => {
            // Generate score elements if neccessary.
            page.generateBleedScores();
            const page_with_def = this._pages_with_definitions.find(page_with_def => page_with_def.page === page);
            if (page_with_def.definition.filters.find(f => f.type === 'binding-layflat')) {
              page.generateLayflatBindingScore();
            }

            this.options.forEach((val, code) => {
              const substitutions = this.elementSubstitutionParams('option', code, val);
              this.performElementSubstitutionsOnPage(page, substitutions);
            });
            this.template_options.forEach((val, code) => {
              const substitutions = this.elementSubstitutionParams('template_option', code, val);
              this.performElementSubstitutionsOnPage(page, substitutions);
            });
          });

          // Adjust bindings, shift elements.
          const new_page_count = this.pages.length;
          const old_page_count = new_page_count - new_pages.length;
          this._adjustBindings(old_page_count, new_page_count);
        }

        return new_pages;
      },

      duplicateSets: function(sets) {
        var self = this;
        var old_page_count = this.pages.length;
        sets.forEach(function(set) {
          self._duplicateSet(set);
        });
        this._adjustBindings(old_page_count, this.pages.length);
      },

      _duplicateSet: function(set) {
        if (!set.grow) {  // sanity check
          throw new Error('Cannot delete page set that is not growable');
        }
        var cloned_set = set.clone({image_store: this.image_store, pdf_store: this.pdf_store});
        this.page_sets.splice(set.position + 1, 0, cloned_set);
      },

      deleteSets: function(sets, opts) {
        var self = this;
        var old_page_count = this.pages.length;
        sets.forEach(function(set) {
          self._deleteSet(set, opts);
        });
        this._adjustBindings(old_page_count, this.pages.length);
      },

      _deleteSet: function(set, opts) {
        if (!set.grow) {  // sanity check
          throw new Error('Cannot delete page set that is not growable');
        }
        if (this.page_sets.length === 1) {
          if (opts && opts.cut_print_mode) {
            // If we're trying to remove the last set from a cut print project,
            // we have to add a new blank set to it first, to not end up with an invalid setless project.
            this.addPages();
          } else {
            throw new Error('Connot delete last set from the project');
          }
        }
        this.page_sets.splice(set.position, 1);
      },

      _adjustBindings: function(old_page_count, new_page_count) {
        var theme_store = this.theme_store;
        this._pages_with_definitions.forEach(function(page_with_definition) {
          var page = page_with_definition.page;
          var definition = page_with_definition.definition;
          if (definition.binding_map_name) {
            var binding_map = theme_store.value_maps[definition.binding_map_name];
            var old_binding = binding_map[old_page_count - 1];
            if (typeof old_binding === 'undefined') {
              throw new Error('Binding value not defined for page count ' + (old_page_count - 1));
            }
            var new_binding = binding_map[new_page_count - 1];
            if (typeof new_binding === 'undefined') {
              throw new Error('Binding value not defined for page count ' + (new_page_count - 1));
            }
            if (old_binding !== new_binding) {
              page.adjustBinding(old_binding, new_binding);
            }
          }
        });
      },

      performElementSubstitutions: function(substitutions, opts) {
        this.forEachPage(page => this.performElementSubstitutionsOnPage(page, substitutions, opts));
        this.layout_store.layouts.forEach(layout => this.performElementSubstitutionsOnLayout(layout, substitutions));
      },

      performElementSubstitutionsOnPage: function(page, substitutions, opts) {
        this._performBackgroundColorSubstitutions(page, substitutions.background_colors || {}, opts);
        this._performLayoutSubstitutions(page, substitutions.layouts || {}, opts);
        this._performPageMaskSubstitutions(page, substitutions.page_masks || {});
        this._performImageSubstitutions(page, substitutions.images || {});
        this._performImageMaskSubstitutions(page, substitutions.image_masks || {});
        this._performImageColorSubstitutions(page, substitutions.image_colors || {});
        this._performImageCropFlagSubstitutions(page, substitutions.image_crop_flags || {});
        this._performImageBorderWidthSubstitutions(page, substitutions.image_border_widths || {});
        this._performImageBorderColorSubstitutions(page, substitutions.image_border_colors || {});
        this._performImageBorderRadiusSubstitutions(page, substitutions.image_border_radiuses || {});
        this._performTextSubstitutions(page, substitutions.texts || {});
        this._performTextColorSubstitutions(page, substitutions.text_colors || {});
        this._performTextFontSubstitutions(page, substitutions.text_fonts || {});
        this._performTextFontSizeSubstitutions(page, substitutions.text_font_sizes || {});
        this._performInlinePageMaskSubstitutions(page, substitutions.ipage_masks || {});
        this._performInlinePageBorderRadiusSubstitutions(page, substitutions.ipage_border_radiuses || {});
      },

      performElementSubstitutionsOnLayout: function(layout, substitutions) {
        this._performImageSubstitutions(layout, substitutions.images || {});
        this._performImageMaskSubstitutions(layout, substitutions.image_masks || {});
        this._performImageColorSubstitutions(layout, substitutions.image_colors || {});
        this._performImageCropFlagSubstitutions(layout, substitutions.image_crop_flags || {});
        this._performImageBorderWidthSubstitutions(layout, substitutions.image_border_widths || {});
        this._performImageBorderColorSubstitutions(layout, substitutions.image_border_colors || {});
        this._performImageBorderRadiusSubstitutions(layout, substitutions.image_border_radiuses || {});
        this._performTextSubstitutions(layout, substitutions.texts || {});
        this._performTextColorSubstitutions(layout, substitutions.text_colors || {});
        this._performTextFontSubstitutions(layout, substitutions.text_fonts || {});
        this._performTextFontSizeSubstitutions(layout, substitutions.text_font_sizes || {});
        this._performInlinePageMaskSubstitutions(layout, substitutions.ipage_masks || {});
        this._performInlinePageBorderRadiusSubstitutions(layout, substitutions.ipage_border_radiuses || {});
      },

      _performImageSubstitutions: function(page, params) {
        Object.keys(params).forEach(name => {
          let substitution = params[name];
          if (typeof substitution === 'number') {
            substitution = `db:${substitution}`;
          }
          page.forEachElement(element => {
            if (element.type === 'image' && element.name === name) {
              element.id = substitution || Px.Editor.ImageElementModel.properties.id.std;
            }
          });
        });
      },

      _performImageMaskSubstitutions: function(page, params) {
        Object.keys(params).forEach(name => {
          const substitution = params[name];
          page.forEachElement(element => {
            if (element.type === 'image' && element.name === name) {
              element.mask = substitution || Px.Editor.ImageElementModel.properties.mask.std;
            }
          });
        });
      },

      _performImageColorSubstitutions: function(page, params) {
        Object.keys(params).forEach(name => {
          const substitution = params[name];
          page.forEachElement(element => {
            if (element.type === 'image' && element.name === name) {
              element.color = substitution || Px.Editor.ImageElementModel.properties.color.std;
            }
          });
        });
      },

      _performImageCropFlagSubstitutions: function(page, params) {
        Object.keys(params).forEach(name => {
          const substitution = params[name];
          page.forEachElement(element => {
            if (element.type === 'image' && element.name === name) {
              if (substitution) {
                element.crop = true;
              } else {
                element.crop = true;
                element.left = 0;
                element.top = 0;
                element.zoom = 0;
              }
            }
          });
        });
      },

      _performImageBorderWidthSubstitutions: function(page, params) {
        Object.keys(params).forEach(name => {
          const substitution = params[name];
          page.forEachElement(element => {
            if (element.type === 'image' && element.name === name) {
              element.border = parseFloat(substitution) || Px.Editor.ImageElementModel.properties.border.std;
            }
          });
        });
      },

      _performImageBorderColorSubstitutions: function(page, params) {
        Object.keys(params).forEach(name => {
          const substitution = params[name];
          page.forEachElement(element => {
            if (element.type === 'image' && element.name === name) {
              element.bordercolor = substitution || Px.Editor.ImageElementModel.properties.bordercolor.std;
            }
          });
        });
      },

      _performImageBorderRadiusSubstitutions: function(page, params) {
        Object.keys(params).forEach(name => {
          const substitution = params[name];
          page.forEachElement(element => {
            if (element.type === 'image' && element.name === name) {
              element.radius = parseInt(substitution, 10) || Px.Editor.ImageElementModel.properties.radius.std;
            }
          });
        });
      },

      _performTextSubstitutions: function(page, params) {
        Object.keys(params).forEach(name => {
          const substitution = params[name];
          page.forEachElement(element => {
            if (element.type === 'text' && element.name === name) {
              if (typeof substitution === 'object') {
                element.text = substitution.text;
                element.placeholder = substitution.placeholder;
              } else {
                element.text = substitution || '';
              }
            }
          });
        });
      },

      _performTextColorSubstitutions: function(page, params) {
        Object.keys(params).forEach(name => {
          const substitution = params[name];
          page.forEachElement(element => {
            if (element.type === 'text' && element.name === name) {
              element.color = substitution || Px.Editor.TextElementModel.properties.color.std;
            }
          });
        });
      },

      _performTextFontSubstitutions: function(page, params) {
        Object.keys(params).forEach(name => {
          const substitution = params[name];
          page.forEachElement(element => {
            if (element.type === 'text' && element.name === name) {
              element.font = substitution || Px.Editor.TextElementModel.properties.font.std;
            }
          });
        });
      },

      _performTextFontSizeSubstitutions: function(page, params) {
        Object.keys(params).forEach(name => {
          const substitution = params[name];
          page.forEachElement(element => {
            if (element.type === 'text' && element.name === name) {
              const std = Px.Editor.TextElementModel.properties.pointsize.std;
              element.pointsize = Math.round(parseFloat(substitution)) || std;
            }
          });
        });
      },

      _performInlinePageMaskSubstitutions: function(page, params) {
        Object.keys(params).forEach(name => {
          const substitution = params[name];
          page.forEachElement(element => {
            if (element.type === 'ipage' && element.name === name) {
              element.mask = substitution || Px.Editor.InlinePageElementModel.properties.mask.std;
            }
          });
        });
      },

      _performInlinePageBorderRadiusSubstitutions: function(page, params) {
        Object.keys(params).forEach(name => {
          const substitution = params[name];
          page.forEachElement(element => {
            if (element.type === 'ipage' && element.name === name) {
              element.radius = parseFloat(substitution) || Px.Editor.InlinePageElementModel.properties.radius.std;
            }
          });
        });
      },

      _performBackgroundColorSubstitutions: function(page, params) {
        Object.keys(params).forEach(page_names => {
          const color = params[page_names];
          if (page.pageNameMatches(page_names.split(',').map(name => name.trim()))) {
            page.bgcolor = color || Px.Editor.PageModel.properties.bgcolor.std;
          }
        });
      },

      _performLayoutSubstitutions: function(page, params, opts) {
        Object.keys(params).forEach(page_names => {
          const layout_name = params[page_names];
          const layout = this.layout_store.layouts.find(layout => layout.name === layout_name);
          if (layout) {
            if (page.pageNameMatches(page_names.split(',').map(name => name.trim()))) {
              page.setLayout(layout);
              if (opts && opts.cut_print_mode) {
                const image_elements = page.image_elements.filter(element => {
                  return element.is_editable_master_element && element.id;
                });
                image_elements.forEach(image_element => {
                  this.autorotateCutPrint(image_element);
                });
              }
            }
          } else {
            console.warn(`Cannot perform layout substitution -- layout not found. Layout ID: ${params[name]}`);
          }
        });
      },

      _performPageMaskSubstitutions: function(page, params) {
        Object.keys(params).forEach(page_names => {
          const mask_src = params[page_names];
          if (page.pageNameMatches(page_names.split(',').map(name => name.trim()))) {
            page.mask = mask_src || Px.Editor.PageModel.properties.mask.std;
          }
        });
      },

      // In cut print mode we automatically generate new pages and fill them with selected images.
      addCutPrintImage: function(image_id) {
        const max_prints = this.theme_store.max_cut_print_quantity;
        const total_prints = this.main_store.total_cut_print_quantity;
        if (max_prints > 0 && total_prints >= max_prints) {
          this.main_store.showNotification(Px.t('Cannot add more prints to this project'), 'error');
          return;
        }

        // Find first page with an empty placeholder.
        let placeholder = this.fillable_placeholders[0];
        if (!placeholder) {
          this.addPages();
          placeholder = this.fillable_placeholders[0];
        }
        if (placeholder) {
          placeholder.fillPlaceholder(image_id);
          this.growVariableSizePage(placeholder);
          this.autorotateCutPrint(placeholder);
        }
      },

      autorotateCutPrint: function(image_element) {
        if (!Px.config.cut_print_autorotate) {
          return;
        }
        const element_orientation = image_element.width / image_element.height >= 1 ? 'landscape' : 'portrait';
        const image_orientation = image_element.image.aspect_ratio >= 1 ? 'landscape' : 'portrait';
        if (image_orientation !== element_orientation) {
          image_element.crotation = 90;
          image_element.tags.push('px:autorotated');
        } else {
          image_element.crotation = 0;
          image_element.tags.remove('px:autorotated');
        }
      },

      growVariableSizePage: function(image_element) {
        // This is currently only used for cut prints, so we can assume we have a growable set with a single page.
        const page_definition = this.theme_store.grow_set_definition.pages[0];
        const aspect_ratio = image_element.image.aspect_ratio;
        const factor = aspect_ratio > 1 ? aspect_ratio : 1/aspect_ratio;
        const page = image_element.page;
        const original_width = page.width;
        const original_height = page.height;
        // For cut prints, we can assume that either the width or the height is variable, but never both.
        // We can also assume that for variable size cut prints, it's always the shorter side that's fixed.
        if (page_definition.has_variable_width) {
          page.width = Math.min(page_definition.max_width, page_definition.height * factor);
        } else if (page_definition.has_variable_height) {
          page.height = Math.min(page_definition.max_height, page_definition.width * factor);
        }
        // We also need to grow any elements on the page. For now we do the easiest thing and just scale
        // elements' width & height, which is usually good enough for prints. We might have to refine that
        // at some point and add some controls, for example you might want to scale image borders or text sizes,
        // you might not want to scale some elements at all, etc.
        const diff_w = page.width - original_width;
        const diff_h = page.height - original_height;
        page.elements.forEach(element => {
          element.width += diff_w;
          element.height += diff_h;
        });
        // Re-generate the bleed scores to make sure that bleed scores remain correct size and don't grow.
        // This works for cut prints, but for a more general solution, we'd have to implement some more
        // involved approach that would know how to intelligently resize elements of different types,
        // perhaps based on various resize flags defined by the admin in the design.
        page.generateBleedScores();
      },

      updateName: function(new_name) {
        var self = this;
        var old_name = this.name;
        if (new_name === old_name) {
          return;
        }
        this.name = new_name;
        this.saving = true;

        fetch(this.projectAPIUrl(), {
          method: 'PUT',
          body: new URLSearchParams({'book[name]': new_name})
        }).then(() => {
          mobx.runInAction(function() {
            self.saving = false;
          });
        }).catch(e => {
          alert(Px.t('Failed saving project name'));
          mobx.runInAction(function() {
            self.saving = false;
            self.name = old_name;
          });
        });
      },

      setOption: function(option, value, opts) {
        // We have to unset any child options which are not triggered by currently selected parent value.
        // We also need to set any triggered child to its default value.
        const resetChildOptions = (parent) => {
          if (parent.type === 'multiple_choice') {
            const child_options = this.option_store.getChildOptions(parent);
            const parent_value = parent.values.find(val => val.code === this.options.get(parent.code));

            child_options.forEach(child => {
              if (parent_value && child.trigger_value_id === parent_value.id) {
                if (child.type === 'multiple_choice') {
                  const default_value = child.values.find(value => value.default);
                  if (default_value) {
                    this.options.set(child.code, default_value.code || '');
                  }
                } else {
                  this.options.delete(child.code);
                }
              } else {
                this.options.delete(child.code);
              }

              resetChildOptions(child);

              if (this.options.has(child.code)) {
                const element_substitutions = this.elementSubstitutionParams(
                  'option',
                  child.code,
                  this.options.get(child.code)
                );
                this.performElementSubstitutions(element_substitutions, opts);
              }
            });
          }
        };

        this.options.set(option.code, value || '');
        resetChildOptions(option);
        const element_substitutions = this.elementSubstitutionParams('option', option.code, value);
        this.performElementSubstitutions(element_substitutions, opts);
      },

      setTemplateOption: function(option, value, opts) {
        // We have to unset any child options which are not triggered by currently selected parent value.
        // We also need to set any triggered child to its default value.
        const resetChildOptions = (parent) => {
          if (parent.type === 'multiple_choice') {
            const child_options = this.option_store.getChildTemplateOptions(parent);
            const parent_value = parent.values.find(val => val.code === this.template_options.get(parent.code));

            child_options.forEach(child => {
              if (parent_value && child.trigger_value_id === parent_value.id) {
                if (child.type === 'multiple_choice') {
                  const default_value = child.values.find(value => value.default);
                  if (default_value) {
                    this.template_options.set(child.code, default_value.code || '');
                  }
                } else {
                  this.template_options.delete(child.code);
                }
              } else {
                this.template_options.delete(child.code);
              }

              resetChildOptions(child);

              if (this.template_options.has(child.code)) {
                const element_substitutions = this.elementSubstitutionParams(
                  'template_option',
                  child.code,
                  this.template_options.get(child.code)
                );
                this.performElementSubstitutions(element_substitutions, opts);
              }
            });
          }
        };

        this.template_options.set(option.code, value || '');
        resetChildOptions(option);
        const element_substitutions = this.elementSubstitutionParams('template_option', option.code, value);
        this.performElementSubstitutions(element_substitutions, opts);
      },

      save: function() {
        // Refuse to save if the project contains any local files that haven't finished uploading yet.
        if (this.local_images.length) {
          alert(Px.t('Cannot save: some images are still uploading. Try again later.'));
          return Promise.reject(new Error('Images still uploading'));
        }
        if (this.local_pdfs.length) {
          alert(Px.t('Cannot save: some PDFs are still uploading. Try again later.'));
          return Promise.reject(new Error('PDFs still uploading'));
        }
        this.saving = true;

        const data = {
          book: this.data_for_save_endpoint
        };
        if (this.data_for_save_endpoint.options) {
          data.clear_options = true;
        }
        if (this.data_for_save_endpoint.template_options) {
          data.clear_template_options = true;
        }

        const promise = fetch(this.projectAPIUrl(), {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          body: $j.param(data)
        }).then(response => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error(`HTTP Error; Status: ${response.status}`);
          }
        }).then(json => {
          // TODO: Be more inteligent; don't replace the entire thing.
          mobx.runInAction(() => {
            this.setLayoutAndStoreSavedSnapshot(json.layout.pages.set);
            this.setOptions(json);
            this.saving = false;
          });
        }).catch(() => {
          this.saving = false;
        });
        return promise;
      },

      copySharedProject: function() {
        return fetch(`/v1/books/${this.project_id}/copy.json`, {
          method: 'POST',
          body: new URLSearchParams({share_code: this.share_code})
        });
      }

    });
  }

  // -------
  // Private
  // -------

  projectAPIUrl() {
    return `/v1/books/${this.project_id}.json`;
  }

  makeGrowSet() {
    if (!this.theme_store.grow_set_definition) {
      throw new Error('Project does not define a growable set');
    }
    const definition = this.theme_store.grow_set_definition;
    const set = Px.Editor.PageSetModel.make({
      double_page: definition.pages[0].is_double,
      grow: true,
      count: definition.count,
      editor: definition.editor,
      fulfillment: definition.fulfillment,
      _left_caption_template: definition.left_caption,
      _center_caption_template: definition.center_caption,
      _right_caption_template: definition.right_caption,
      // Circular dependency.
      project_store: this
    });
    return set;
  }

  elementSubstitutionParams(type, code, value) {
    const option = this.getOptionByCode(type, code);
    if (option) {
      if (option.type === 'multiple_choice') {
        const option_value = option.values.find(val => val.code === value);
        if (option_value) {
          return option_value.element_substitutions;
        }
      } else if (option.type === 'color' && value) {
        const substitutions = {};
        Object.keys(option.element_substitutions).forEach(substitution_type => {
          substitutions[substitution_type] = {};
          Object.keys(option.element_substitutions[substitution_type]).forEach(element_name => {
            substitutions[substitution_type][element_name] = value;
          });
        });
        return substitutions;
      } else if (option.type === 'font') {
        const substitutions = {};
        option.target_element_names.forEach(name => {
          substitutions[name] = value || '';
        });
        return {text_fonts: substitutions};
      } else if (option.type === 'text') {
        if ((option.target_template || '').trim()) {
          value = new liquidjs.Liquid().parseAndRenderSync(option.target_template, {value: value});
        }
        const substitutions = {};
        option.target_element_names.forEach(name => {
          value = value || '';
          substitutions[name] = {text: value, placeholder: !value.trim()};
        });
        return {texts: substitutions};
      }
    }
    return {};
  }

  getOptionByCode(type, code) {
    if (type === 'template_option') {
      return this.option_store.getTemplateOptionByCode(code);
    } else {
      return this.option_store.getOptionByCode(code);
    }
  }

  setOptions(data) {
    this.options.replace(data.options);
    this._saved_options.replace(data.options);
    this.template_options.replace(data.template_options);
    this._saved_template_options.replace(data.template_options);
  }

};
